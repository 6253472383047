$form-field-text-colour: $color-text-default !default;

//  Base place-holder of all form elements
// ========================================
//  Define a global form field place-holder for all:
//  inputs, selects, text-areas, radio-buttons &
//  check-boxes
%form__field {
    appearance: none;
    background-color: $color-white;
    border: $border-width-mid solid $color-off-black;
    border-radius: $border-radius-form-field;
    color: $color-text-default;
    display: block;
    font-family: $font-base;
    line-height: $line-height-ratio;
    margin-top: $space-half;
    max-width: 100%;
    padding: $space-half;
    vertical-align: baseline;
    width: 100%;

    &:focus {
        border: $border-width-mid solid $color-link-focus;
        outline: $border-width-small solid $color-link-focus;
    }

    &--disabled {
        border-color: $color-disabled;
    }

    &--upload {
        line-height: 1;
    }

    &[type='date'],
    &[type='time'] {
        appearance: none;
        min-height: $space-double;
    }
}

//  Bordered place-holder
// =======================
//  For use with all elements all, except:
//  check-boxes & radio-buttons
%form__field--bordered {
    background-color: $color-white;
    border: $border-width-small solid $color-border-input;
}
