%alert--error,
.alert--error {
    @extend %alert--danger;

    .alert__text {
        display: block;
    }

    .button {
        @extend %button--primary;

        margin-top: $space-half;

        &:last-child {
            @include respond-max($small-width) {
                margin-bottom: $space-half;
            }
        }
    }
}
