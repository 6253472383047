// Directory search widget
@mixin directory-search--small() {
    .form__append-group {
        display: block;

        .button {
            display: block;
            margin: $space-half 0 0;
            width: 100%;
        }
    }
}

.widget-directory-search {
    .form {
        margin: 0;
    }
}

.widget-directory-search {
    @extend %boxed;
    @extend %boxed--border-primary;
    @extend %boxed--flush;

    @include respond-max($desk-break) {
        @include directory-search--small;
    }

    .widget-width--small & {
        @include directory-search--small;
    }

    .widget-width--large &,
    .widget-width--medium & {
        .form__append-group {
            @include respond-max($desk-break) {
                flex-wrap: wrap;
                justify-content: flex-start;
            }
        }

        .button {
            // Equal sized buttons for different input groups
            min-width: $space * 7;

            @include respond-max($desk-break) {
                margin: $space-half 0;
            }
        }
    }
}
