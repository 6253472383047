// Breadcrumb navigation
//
// Renders the list into vertical breadcrumb mode
//
// Markup:
// <ol class="nav nav--breadcrumb">
//   <li><a>Home</a></li>
//   <li><a>Contact</a></li>
//   <li><a>About</a></li>
// </ol>
//
// Styleguide 14.2

.breadcrumb {
    font-size: $milli;
    margin-bottom: $space-third;
    text-align: center;

    .home-page & {
        display: none;
    }
}

.breadcrumb__prefix {
    @extend %visually-hidden;
}

.list--breadcrumb {
    display: block;
    margin: 0;
    padding: 0;

    .list__item {
        display: inline;

        &:last-child::after {
            display: none;
        }

        &::after {
            content: '/';
            display: inline;
            padding: 0 $space-twelfth 0 ($space-third - $space-eighth);
        }
    }

    a {
        @extend %link-style;

        display: inline-block;
    }

    span {
        display: inline;
        font-weight: $font-weight-bold;
    }

    a,
    span {
        text-transform: capitalise;
    }
}
