.services {
    // scss-lint:disable ImportantRule
    margin-top: -$space !important; // Overwrite %module
    // scss-lint:enable ImportantRule
    text-align: center;

    .list {
        @extend %cf;
        margin-bottom: 0;

        .widget-width--medium &,
        .widget-width--large & {
            @include respond-min($tablet-break) {
                margin-left: -$gutter;
            }
        }
    }

    .list__item {
        padding-top: $space-half;

        @include respond-min($tablet-break) {
            .widget-width--medium &,
            .widget-width--large & {
                float: left;
                margin: 0;
                padding-left: $gutter;
                padding-top: $space;
                width: percentage(1 / 2);
            }
        }

        @include respond-min($desk-break) {
            .widget-width--large & {
                float: left;
                margin: 0;
                padding-left: $gutter;
                width: percentage(1 / 3);
            }
        }
    }
}

.services__block {
    @extend %link-style;

    background-color: $color-white;
    border-radius: $boxed__border-radius;
    box-shadow: $boxed__box-shadow;
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;

    .button {
        border-color: $color-grey-light;
        border-radius: $space-quarter 0 0 $space-quarter;
        float: left;
        max-width: $space-double + $space;
        overflow: visible;
        padding: $space-sixth $space-half $space-sixth $space-third;
        position: relative;
        width: auto;

        @include respond-max($x-small-width) {
            border-radius: $border-width-xx-large $border-width-xx-large 0 0;
            max-width: none;
            width: 100%;
        }

        @include respond-min($tablet-break) {
            width: percentage(1 / 4);
        }

        @include respond-min($mobile-break) {
            height: 100%;
        }

        @include respond-max($x-small-width) {
            .icon {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $color-grey-light;
            border-color: transparent;

        }

        &::before {
            @include button-arrow(right);

            @include respond-max($x-small-width) {
                display: none;
            }
        }

        &::after {
            content: none;
        }
    }

    .icon {
        margin-top: $space-twelfth;
    }

    &:hover,
    &:focus {
        background-color: $color-grey-light;

        .button {
            background-color: $color-white;

            &::before {
                border-left-color: $color-white;
            }
        }
    }

    .button__inner {
        @include respond-min($tablet-break) {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.services__toggle {
    @extend %cf;

    text-align: center;
}

.services__more-button {
    display: none;
    margin: 0 auto;
    margin: $space auto;

    .js & {
        display: inline-block;
    }

    .icon {
        left: $space-half;
        position: relative;
        transition: $transition-speed transform;
    }

    &[aria-expanded='true'] {
        .icon {
            transform: rotate(180deg);
        }
    }
}

.services__title {
    display: inline-block;
    padding: $space-half + $space-third;

    @include respond-min($tablet-break) {
        width: percentage(3 / 4);
    }

    @include respond-min($desk-break) {
        font-size: 1.1rem;
    }

    @include respond-max($x-small-width) {
        text-align: center;
        width: 100%;
    }
}

.services__more {
    display: block;
    float: left;
    margin: 0;
    transition: $transition-speed-fast max-height $bezier, $transition-speed-fast opacity;
    width: 100%;

    @include respond-min($tablet-break) {
        padding: 0 0 0 $gutter;
    }

    .js & {
        display: none;
    }

    &[aria-hidden='false'],
    &.is-active[aria-hidden='true'] {
        display: block;
        max-height: 0;
        opacity: 0;
    }

    &.is-active {
        opacity: 1;
    }

    .list {
        margin: 0;
    }
}
