.menu-navigation {
    .navigation {
        background-color: $color-secondary;
        padding-top: $space-two-thirds;
    }

    .list__item {
        transform: translateX(75%);
        transition: $transition-speed transform;

        &.is-animated {
            transform: translateX(0);
        }
    }

    .list__link {
        &:active,
        &:focus {
            outline-color: $color-white;
        }
    }

    .navigation__button {
        @include button-slide($color-secondary);

        &,
        &:visited,
        &:hover,
        &:focus {
            border-color: $color-white;
        }
    }
}

.menu-navigation__heading {
    color: $color-white;
    font-size: $zeta;
    margin-bottom: $space-and-half;
}

.no-js .menu-navigation {
    .navigation {
        display: block;
        position: relative;
        width: 100%;
    }

    .navigation__button {
        display: none;
    }

    .list__item {
        transform: initial;
    }
}
