// Based on Codrops example - http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/

.form__field--upload,
%form__field--upload {
    line-height: 1;
    min-height: auto;

    .js & {
        @extend %visually-hidden;
    }

    &:focus,
    &.has-focus {
        ~ .form__label--upload {
            @include focus-outline;
        }
    }
}

.form__label--upload,
%form__label--upload {
    @extend %button;
    @extend %button--primary;
    @extend %button--icon-right;

    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .form__component--file & {
        margin: $slug-small 0;
        padding-right: $space-double + $space-half;

        @include respond-min($small-width) {
            width: auto;
        }
    }

    .form__required-note {
        display: none;
    }

    // scss-lint:disable VendorPrefix ImportantRule
    &::-moz-selection,
    &::selection {
        background-color: transparent !important;
        color: $color-primary-offset !important;
    }
    // scss-lint:enable VendorPrefix ImportantRule
}
