$list-indent: $space;
$bullet-size: remToPx($space-third);
$bullet-space: $space-half;
$bullet-color: $color-primary-light;

.navigation-widget {
    padding: $space-three-quarters $space;
}

%list--navigation,
.list--navigation {
    @extend %list--bullet;

    .list__item {
        @extend %list__item;

        margin-top: $space-third;
        padding-left: $list-indent;

        &::before {
            background: $bullet-color;
            border-radius: 50%;
            content: '';
            display: inline-block;
            float: left;
            height: $bullet-size;
            margin-left: -$list-indent;
            margin-right: $bullet-space;
            margin-top: $space-third;
            min-height: $bullet-size;
            min-width: $bullet-size;
            text-align: right;
            width: $bullet-size;

            @include respond-min($desk-break) {
                border-width: $border-width-xx-large;
            }
        }
    }
}

.list-item--print {
    .no-js & {
        display: none;
    }

    .list__button {
        -webkit-appearance: none;
        background: none;
        border: 0;
        color: $color-link;
        margin: 0;
        padding: 0;

        &:hover {
            color: $color-link-hover;
            text-decoration: underline;
        }
    }
}
