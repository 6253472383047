// What's on widget
.widget-whats-on {
    @extend %boxed;
    @extend %boxed--border-primary;
    @extend %boxed--flush;

    .listing {
        margin-top: $space;
    }
}
