$pages-in-accent-color: 'primary-light';
// ========================================

.boxed--pages-in {
    @extend %boxed--border-#{$pages-in-accent-color};

    .boxed__list {
        margin: -#{$space-third} 0 0 0;

        @include respond-min($small-width) {
            column-count: 2;
            column-gap: $space-and-half;
        }
    }

    .list__item {
        break-inside: avoid;
        -webkit-column-break-inside: avoid;
        margin-bottom: 0;
        padding-bottom: $space-eighth;
        padding-top: $space-eighth;
        page-break-inside: avoid;
    }
}
