$sidebar-width: $boxed-offset;
$sidebar-offset: $sidebar-width + $gutter;

.layout {
    @extend %cf;
}

.layout__main {
    @include respond-min($tablet-break) {
        float: left;
        width: calc(100% - #{$sidebar-offset});
    }
}

.layout__sidebar {
    margin-top: $leading;

    @include respond-min($tablet-break) {
        float: right;
        margin-top: 0;
        width: $sidebar-width;
    }
}

.layout__main,
.layout__sidebar {
    > *:first-child {
        margin-top: 0;
    }
}
