// Quotes
//
// Base blockquote class.
// See sass/objects/_blockquotes.scss.
//
// Markup:
// <blockquote class="blockquote {$modifiers}">
//     For 50 years, WWF has been protecting the future of nature. The world's leading conservation organization, WWF works in 100 countries and is supported by 1.2 million members in the United States and close to 5 million globally.
// </blockquote>
//
// Styleguide 3
blockquote,
%blockquote,
.blockquote {
    @extend %epsilon;

    color: $color-primary;
    font-size: $epsilon;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: 1.3;
    padding: $space-sixth $space $space ($space-and-two-thirds * 2);
    position: relative;

    &::before {
        background-size: cover;
        border-radius: 10%;
        color: $color-primary;
        content: '“';
        font-size: $giga;
        height: $space-double;
        left: 0;
        line-height: 1.22;
        padding-top: $space-third;
        position: absolute;
        text-align: center;
        text-indent: -.025em;
        top: 0;
        width: $space-double;
    }

    p,
    %blockquote__text,
    .blockquote__text {

    }

    cite {
        color: lighten($color-text-default, 20%);
        display: block;
        font-size: $zeta;
        font-style: normal;
        font-weight: $font-weight-normal;
        line-height: $line-height-ratio;
    }
}
