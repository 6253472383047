.suppliers__heading {
    @extend %visually-hidden;
}

.suppliers__content {
    font-size: $micro;
    margin: $space-third 0 0;

    @include respond-min($small-width) {
        margin: 0;
    }
}

.suppliers__text {
    @include respond-max($small-width) {
        display: block;
    }
}

a.suppliers__link {
    @extend %link-style;

    color: $color-standout-offset;
    font-weight: $font-weight-bold;
    margin: $space-twelfth 0 ($space-sixth - $space-twelfth);

    &:hover,
    &:focus,
    &:active {
        color: $color-white;
        text-decoration: underline;
    }
}

a.suppliers__link--powered-by,
a.suppliers__link--designed-by {
    &:hover,
    &:focus,
    &:active {
        strong {
            text-decoration: underline;
        }
    }
}
