// Images

$featured-image-max:        45%;
$featured-image-max-large:  percentage(4 / 10);
$avatar-image-max:          pxToRem(60px);
$thumbnail-image-max:       pxToRem(150px);

img {
    display: block; // remove weird invisible bottom margin
}

.image--feature,
.image--caption {
    float: left;
    margin: 0 0 $leading;
    width: 100%;

    @include respond-min($x-small-width) {
        float: right;
        margin: 0 0 $leading $space;
        max-width: $featured-image-max;
    }

    @include respond-min($large-width) {
        margin: 0 0 $space $space;
        max-width: $featured-image-max-large;

        .boxed--offset & { // if nested, push outside boundaries
            margin: 0 #{-$image-offset} $space $space;
        }
    }
}

.image--caption {
    img {
        width: 100%;
    }

    .image--feature {
        float: none;
        margin: 0;
        max-width: 100%;
    }
}

.image--avatar {
    max-width: $avatar-image-max;

    img {
        border: $border-width-mid solid $color-border;
        height: auto;
    }
}

.image--gallery {
    display: block;
}

%image__caption,
.image__caption {
    border-bottom: $border-width-mid solid $color-border;
    color: $color-text-primary;
    padding: $space-quarter 0;
}

.image--thumbnail {
    height: auto;
    max-width: $thumbnail-image-max;
    width: 20%;
}

.image-link {
    max-width: 100%;
}
