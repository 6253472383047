// Multimedia widget
.widget--multimedia {
    @extend %boxed;
    @extend %boxed--border-primary;
    @extend %boxed--flush;

    object {
        width: 100%;
    }
}
