%list--rich,
.list--rich {
    .list__item {
        @extend %cf;

        border-bottom: $border-width-small solid $color-grey-light;
        margin: 0;
        padding: $space-third $space-half $space-third 0;

        &:first-child {
            border-top: $border-width-small solid $color-grey-light;
            padding-top: $space-third;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    .list {
        margin-top: $space;

        .list__item {
            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    .image--thumbnail,
    .image--avatar {
        float: right;
        margin: 0 0 $space-third $space-third;
        width: percentage(1 / 4);
    }
}
