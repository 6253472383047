// Container
%container,
.container {
    @extend %cf;
    @include container;

    padding: 0 $space-two-thirds;
}

%container--flush,
.container--flush {
    padding-left: 0;
    padding-right: 0;
}

.container {
    .container {
        @extend %container--flush;
    }
}

.container--space-bottom {
    margin-bottom: $leading;
}

.container--wide,
%container--wide {
    max-width: $container-wide;
}


.container--inner,
%container--inner {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-inner;
}

.outer-container {
    @extend %container;

    .modular & {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.measure {
    max-width: $content-max-width;
}
