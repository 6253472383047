// Local variables
$alertIconSize: $space-double !default;

.alert {
    background-color: $color-white;
    border: $color-off-white solid $space-quarter;
    margin: 0 0 $space-double;
    padding: $space-half $space-half 0;
    position: relative;

    @include respond-max($small-width) {
        background-color: $color-off-white;
    }

    @include respond-min($small-width) {
        border-left-width: $alertIconSize + $space-and-half;
        min-height: $alertIconSize * 2;
        padding: ($space - $space-sixth) $space $space;
    }

    &,
    *:not(.button__inner):not(.button):not(.link-cta) {
        color: $color-text-default;
    }

    .home-page &:first-child {
        margin-top: $space-and-half;
    }

    &::before {
        background-size: contain;
        content: '';
        display: inline-block;
        height: $alertIconSize;
        margin: ($space-sixth * -1) $space-half $space-sixth ($space-sixth * -1);
        position: relative;
        vertical-align: middle;
        width: $alertIconSize;

        @include respond-min($small-width) {
            display: block;
            float: none;
            left: ($alertIconSize + $space-and-half + $alertIconSize) / 2 * -1;
            margin: $space-fifth + $space-eighth 0 0;
            position: absolute;
            top: 0;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    .listing + & {
        margin-top: $leading;
    }

    .callout + & {
        margin-top: $space-half;
    }
}

.alert__heading {
    @extend %boxed__heading;
    @extend %epsilon;

    margin-bottom: $space-eighth;

    @include respond-max($small-width) {
        display: inline-block;
        margin-bottom: $space-third;
        vertical-align: middle;
        width: calc(100% - #{$alertIconSize + $space-half});
    }
}

.alert__subheading {
    @extend %boxed__heading;

    display: block;
    font-size: $zeta;
    margin: 0;
}

.alert__list {
    clear: both;
    margin-bottom: 0;
    margin-top: $space-half;
}

.alert__text {
    display: inline-block;
    margin-bottom: 0;
    margin-top: $space-half;

    + .button {
        margin-top: 0;
    }
}

.alert__heading,
.alert__list,
.alert__text {
    @include respond-max($small-width) {
        &:last-child {
            padding-bottom: $space-half;
        }
    }
}

.alert__form {
    clear: both;

    @include respond-max($small-width) {
        background: $color-white;
        margin: $space-half ($space-half * -1) 0;
        padding: $space-half;
    }
}

a:not(.button).alert__link {
    display: inline-block;
    margin-right: $space;
    position: relative;
    text-decoration: underline;

    &::after {
        bottom: 0;
        content: '>';
        left: 100%;
        padding-left: $space-sixth;
        position: absolute;
    }

    .alert__link-text {
        text-decoration: underline;
    }
}

.alert__heading {
    + * {
        margin-top: 0;
    }
}

.alert--site-announcement {
    @extend %alert--warning;
}
