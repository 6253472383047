.button--icon-right,
%button--icon-right {
    &,
    &:visited {
        padding-right: $space-triple;
    }

    &::before {
        background-position: center;
        background-size: 100%;
        content: '';
        height: $space-two-thirds;
        position: absolute;
        right: $space-and-two-thirds;
        top: 50%;
        transform: translateY(-50%);
        width: $space-two-thirds;
        z-index: 2;
    }

    .icon {
        display: inline-block;
        height: $button-arrow-height;
        margin-left: $space-quarter;
        vertical-align: middle;
        width: $button-arrow-width;
    }
}

.button--icon-right-default {
    @extend %button--icon-right;

    &,
    &:visited {
        padding-right: $space-double;
    }
}
