$list-indent: $space;
$bullet-size: $space-and-third;
$bullet-outer-space: $space-half;
$bullet-inner-space: 2px;

.list--numbered,
%list--numbered {
    counter-reset: list_item_count;
    list-style: none;
    margin-left: $list-indent;
    padding: $space-fifth 0;

    .list__item,
    %list__item {
        counter-increment: list_item_count;
        margin: 0;
        margin-bottom: $space-half;
        padding: $space-half 0 $space-half $bullet-size;
    }

    .list__link {
        display: inline-block;
        font-weight: $font-weight-bold;
        margin-left: $space;
        position: relative;
        top: $bullet-inner-space;

        &::before {
            background-color: $color-purple-light;
            border-radius: 50%;
            color: $color-white;
            content: counter(list_item_count);
            left: -#{$space-half + $bullet-size};
            min-height: $bullet-size;
            min-width: $bullet-size;
            padding: $bullet-inner-space;
            position: absolute;
            text-align: center;
            top: -#{$bullet-inner-space};
            transition: background-color $transition-speed ease;
        }

        &:hover {
            &::before {
                background-color: mix($color-primary, $color-white, 70%);
            }
        }
    }

    .list__item--active-child {
        .list__link {
            font-weight: $font-weight-normal;

            &::before {
                background-color: $color-white;
                border: $border-width-small solid $bullet-color;
                color: $color-black;
                font-weight: $font-weight-bold;
            }

            &:hover {
                color: $color-link;
                text-decoration: none;
            }

            &:active,
            &:focus {
                outline: none;
            }
        }
    }

    .list__link--disabled {
        font-weight: $font-weight-normal;
        margin-left: $space;
        position: relative;
        top: $bullet-inner-space;

        &::before {
            background-color: $color-white;
            border: $border-width-small solid $bullet-color;
            border-radius: 50%;
            color: $color-black;
            content: counter(list_item_count);
            font-weight: $font-weight-bold;
            left: -#{$space-half + $bullet-size};
            min-height: $bullet-size;
            min-width: $bullet-size;
            padding: $bullet-inner-space;
            position: absolute;
            text-align: center;
            top: -#{$bullet-inner-space};
            transition: background-color $transition-speed ease;
        }
    }
}
