// ========================================================
//  Input Placeholders
// ========================================================
//  Styling for placeholder attributes
//  Ref: /sass/tools/_mixins.scss

::-webkit-input-placeholder {
    color: transparent;

    @include respond-min($mobile-break) {
        color: $color-primary;
    }

    @at-root:focus#{&} {
        color: lighten($color-text-muted, 20%);
    }
}

::-moz-placeholder {
    /* FF 4-18 */
    color: transparent;
    opacity: 1;

    @include respond-min($mobile-break) {
        color: $color-primary;
    }

    @at-root:focus#{&} {
        color: lighten($color-text-muted, 20%);
    }
}

::-moz-placeholder {
    /* FF 19+ */
    color: transparent;
    opacity: 1;

    @include respond-min($mobile-break) {
        color: $color-primary;
    }

    @at-root:focus#{&} {
        color: lighten($color-text-muted, 20%);
    }
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: transparent;

    @include respond-min($mobile-break) {
        color: $color-primary;
    }

    @at-root:focus#{&} {
        color: lighten($color-text-muted, 20%);
    }
}

.placeholdr {
    /* Placeholdr jQuery plug-in */
    color: transparent;

    @include respond-min($mobile-break) {
        color: $color-primary;
    }

    @at-root:focus#{&} {
        color: lighten($color-text-muted, 20%);
    }
}


// ========================================================
