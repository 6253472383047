.button--warning,
%button--warning {
    @include button-slide($color-warning, darken($color-warning, 5%), $color-off-black);

    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        border-color: $color-off-black;
    }
}
