// ========================================================
//  Brand palette
// ===============

//  Primary palette
// =================
$primary-palette: (
    'purple':                   #380b37,
    'purple-light':             #552f54,
    'pink':                     #ca0f5f,
    'pink-light':               #cd0e61,
    'yellow':                   #f9b400,
    'yellow-light':             #fdecbf,
    'orange':                   #fe6930,
    'orange-light':             #fed9cb,
    'teal':                     #34aaad,
    'teal-light':               #bfe4e5,
    'teal-dark':                #007579,
    'green':                    #76b72d,
    'green-light':              #ddedca,
    'blue':                     #2193d5,
    'blue-light':               #c7e4f4,
    'violet':                   #c979c7,
    'violet-light':             #f1ddf1
);

//  Neutral palette
// =================
$color-black:                   #000;
$color-off-black:               #202020;
$color-white:                   #fff;
$color-off-white:               #f9f9f9;
$color-grey:                    #ccc;
$color-grey-light:              #ececec;
$color-grey-lighter:            #f1f1f1;
$color-grey-dark:               #aaa;
$color-grey-darker:             #888;

// ========================================================


// ========================================================
//  J∆DU brand palette
// ====================
$color-jadu-blue:               #95bed9;
$color-jadu-blue-dark:          #2080c0;
$color-spacecraft-yellow:       #ffe807;

// ========================================================


// ========================================================
//  Non-brand palette
// ===================

//  UI palette
// ============
//  These can updated to use colours from the `Primary palette`
$ui-palette: (
    'blue':                    #226fb4,
    'blue-dark':               #095294,
    'green':                   #008000,
    'orange':                  #ffa500,
    'purple':                  #6d2077,
    'red':                     #ce0808,
    'red-dark':                #cd171b,
    'yellow':                  #ffe13b
);

// ========================================================


// ========================================================
//  Site Colours
// ==============

//  Primary
// =========
$color-primary:                 map-get($primary-palette, 'purple');
$color-primary-light:           map-get($primary-palette, 'purple-light');
$color-primary-dark:            mix($color-black, $color-primary, 15%);
$color-primary-darker:          mix($color-black, $color-primary, 40%);
$color-primary-offset:          $color-white;

//  Text
// ======
$color-text-default:            $color-off-black;
$color-text-heading:            $color-off-black;
$color-text-muted:              $color-grey;
$color-text-primary:            $color-primary;
$color-text-disabled:           #757575;

//  Secondary
// ===========
$color-secondary:               map-get($primary-palette, 'pink');
$color-secondary-light:         map-get($primary-palette, 'pink-light');
$color-secondary-dark:          darken($color-secondary, 10%);
$color-secondary-offset:        mix($color-white, $color-secondary, 40%);

//  Response
// ==========
$color-success:                 map-get($ui-palette, 'green');
$color-warning:                 map-get($ui-palette, 'orange');
$color-danger:                  map-get($ui-palette, 'red');
$color-danger-text:             darken($color-danger, 40%);
$color-danger-border:           map-get($ui-palette, 'red-dark');
$color-disabled:                $color-grey;

$background--success:           mix($color-success, $color-white, 60%);
$background--warning:           mix($color-warning, $color-white, 80%);
$background--danger:            mix($color-danger, $color-white, 40%);
$background--disabled:          mix($color-disabled, $color-white, 40%);
$background--information:       mix($color-grey, $color-black, 90%);

//  Stand-out
// ===========
$color-standout:                $color-grey;
$color-standout-offset:         $color-white;

//  Button hover colours
// =====================
$color-button-primary:          lighten($color-primary, 10%);
$color-button-secondary:        $color-primary;
$color-button-standout:         darken($color-standout, 5%);

//  Link
// ======
$color-link:                    lighten($color-primary-light, 10%);
$color-link-visited:            $color-primary-dark;
$color-link-hover:              $color-primary;
$color-link-focus:              map-get($primary-palette, 'blue');
$color-link-focus-alt:          $color-link-focus;

//  Link colour themes
// --------------------
$color-link-primary-hover:          lighten($color-primary, 70%);
$color-link-primary-visited:        lighten($color-primary, 60%);
$color-link-secondary-hover:        lighten($color-secondary, 70%);
$color-link-secondary-visited:      lighten($color-secondary, 60%);
$color-link-primary-light-hover:    lighten($color-primary-light, 60%);
$color-link-primary-light-visited:  lighten($color-primary-light, 50%);

//  Misc
// ======
$color-site-background:         $color-grey;
$color-subtle:                  $color-off-white;

$bullet-color:                  $color-primary-light;

//  Borders
// =========
//   Default border colours
//   ----------------------
$color-border:                  $color-grey;
$color-border-light:            map-get($primary-palette, 'blue');
$color-border-mid:              $color-border;
$color-border-dark:             $color-grey-dark;
$color-border-input:            $color-text-default;

//   Site specific border colours
//   ----------------------------
$color-border-primary:          lighten($color-primary, 10%);
$color-border-secondary:        lighten($color-secondary, 10%);

//   Boxed border colours
//   ----------------------------
$color-purple:                  map-get($primary-palette, 'purple');
$color-purple-light:            map-get($primary-palette, 'purple-light');
$color-pink:                    map-get($primary-palette, 'pink');
$color-yellow:                  map-get($primary-palette, 'yellow');
$color-yellow-light:            map-get($primary-palette, 'yellow-light');
$color-orange:                  map-get($primary-palette, 'orange');
$color-orange-light:            map-get($primary-palette, 'orange-light');
$color-teal:                    map-get($primary-palette, 'teal');
$color-teal-light:              map-get($primary-palette, 'teal-light');
$color-teal-dark:               map-get($primary-palette, 'teal-dark');
$color-green:                   map-get($primary-palette, 'green');
$color-green-light:             map-get($primary-palette, 'green-light');
$color-blue:                    map-get($primary-palette, 'blue');
$color-blue-light:              map-get($primary-palette, 'blue-light');
$color-violet:                  map-get($primary-palette, 'violet');
$color-violet-light:            map-get($primary-palette, 'violet-light');

$theme: (
    'yellow':           ($color-yellow, $color-black),
    'primary':          ($color-primary, $color-black),
    'purple':           ($color-primary, $color-black),
    'primary-light':    ($color-primary-light, $color-black),
    'purple-light':     ($color-primary-light, $color-black),
    'pink':             ($color-pink, $color-black),
    'teal':             ($color-teal, $color-black),
    'teal-dark':        ($color-teal-dark, $color-black),
    'green':            ($color-green, $color-black),
    'orange':           ($color-orange, $color-black),
    'blue':             ($color-blue, $color-white),
    'violet':           ($color-violet, $color-white)
);

// ========================================================
