.button--disabled,
%button--disabled,
.button[disabled] {
    &,
    &:visited,
    &:hover,
    &:focus,
    &:active {
        background-color: lighten($color-disabled, 10%);
        border: $border-width-mid solid lighten($color-disabled, 10%);
        color: lighten($color-disabled, 30%);
        cursor: default;
        text-decoration: none;
    }

    .js & {
        &::after {
            display: none;
        }
    }
}
