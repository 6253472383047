// ========================================================
//  General Settings
// ========================================================
//  Contains settings for Typography, Spacing, Breakpoints
//  and some helpers for debugging.

// ========================================================
//  Typography
// ============

//  Base Type Size
// ================
$base-font-size:                16px;
$base-line-height:              24px;
$line-height-ratio:             $base-line-height / $base-font-size;

// Body font size
$body-font-size-px:             18px;
$body-font-size:                $body-font-size-px / $base-font-size * 1rem;

//  Oversized Type Range
// ======================
$giga-px:                       72px;
$giga:                          $giga-px / $base-font-size * 1rem;

$mega-px:                       48px;
$mega:                          $mega-px / $base-font-size * 1rem;

$kilo-px:                       38px;
$kilo:                          $kilo-px / $base-font-size * 1rem;

//  Standard Type Range
// =====================
$alpha-px:                      34px;
$alpha:                         $alpha-px / $base-font-size * 1rem;
$alpha-line-height:             1.1;

$beta-px:                       30px;
$beta:                          $beta-px / $base-font-size * 1rem;
$beta-line-height:              1.2;

$gamma-px:                      26px;
$gamma:                         $gamma-px / $base-font-size * 1rem;
$gamma-line-height:             1.26;

$delta-px:                      24px;
$delta:                         $delta-px / $base-font-size * 1rem;
$delta-line-height:             1.3;

$epsilon-px:                    20px;
$epsilon:                       $epsilon-px / $base-font-size * 1rem;
$epsilon-line-height:           1.3;

$zeta-px:                       18px;
$zeta:                          $zeta-px / $base-font-size * 1rem;
$zeta-line-height:              1.4;

$normalis-px:                   $base-font-size;
$normalis:                      $normalis-px / $base-font-size * 1rem;

//  Small Type Range
// ==================
$milli-px:                      14px;
$milli:                         $milli-px / $base-font-size * 1rem;

$micro-px:                      12px;
$micro:                         $micro-px / $base-font-size * 1rem;

$font-family:                   'Source Sans Pro', sans-serif;
$font-family-block:             $font-family;
$font-base:                     $font-family;


//  Weights
// =========
$font-weight-normal:            400;
$font-weight-semibold:          500;
$font-weight-bold:              600;
$font-weight-heading:           $font-weight-semibold;

//  Measure
// =========
$content-max-width:             44em;

// ========================================================




// ========================================================
//  Breakpoints
// =============

//  Variables
// ===========
$full-width:                    1288px;
$x-large-width:                 1020px;
$large-width:                   960px;
$mid-width:                     760px;
$small-width:                   600px;
$small-mid-width:               500px;
$x-small-width:                 460px;
$xx-small-width:                375px;
$smallest-width:                320px;

$desk-break:                    $large-width;
$tablet-break:                  $mid-width;
$mobile-break:                  $small-width;


//  Widget Snaps
// ==============
$widget-snap-small:             $small-mid-width;
$widget-snap-medium:            $mid-width;
$widget-snap-large:             $large-width;


//  Map
// =====
$breakpoints: (
    'x-small-width'   '(min-width: #{$x-small-width})',
    'small-width'     '(min-width: #{$small-width})',
    'mid-width'       '(min-width: #{$mid-width})',
    'large-width'     '(min-width: #{$large-width})',
    'x-large-width'   '(min-width: #{$x-large-width})',
    'full-width'      '(min-width: #{$full-width})'
);

// ========================================================




// ========================================================
//  Layout
// ========

//  Spacing
// =========
$space:                         $line-height-ratio * 1rem;       // 24px   -- 1.5rem
$space-half:                    $space / 2;                      // 12px   -- 0.75rem
$space-hard:                    $space / 12 * 5;                 // 10px   -- 0.625rem
$space-third:                   $space / 3;                      //  8px   -- 0.5rem
$space-quarter:                 $space / 4;                      //  6px   -- 0.375rem
$space-fifth:                   $space / 5;                      //  4.8px -- 0.3rem
$space-sixth:                   $space-third / 2;                //  4px   -- 0.25rem
$space-eighth:                  $space / 8;                      //  3px   -- 0.1875rem
$space-twelfth:                 $space-sixth / 2;                //  2px   -- 0.125rem
$space-two-thirds:              $space-third * 2;                // 16px   -- 1 rem
$space-three-quarters:          $space - $space-quarter;         // 18px   -- 1.125rem
$space-and-eighth:              $space + $space-eighth;          // 27px   -- 1.6875rem
$space-and-sixth:               $space + $space-sixth;           // 28px   -- 1.75rem
$space-and-fifth:               $space + $space-fifth;           // 28.8px -- 1.8rem
$space-and-quarter:             $space + $space-quarter;         // 30px   -- 1.875rem
$space-and-third:               $space + $space-third;           // 32px   -- 2 rem
$space-and-half:                $space + $space-half;            // 36px   -- 2.25rem
$space-and-two-thirds:          $space + $space-two-thirds;      // 40px   -- 2.5rem
$space-and-three-quarters:      $space + $space-three-quarters;  // 42px   -- 2.625rem
$space-double:                  $space * 2;                      // 48px   -- 3 rem
$space-triple:                  $space * 3;                      // 72px   -- 4.5rem
$space-quadruple:               $space * 4;                      // 96px   -- 6 rem

//  Gutters
// =========
//  Space between columns
$gutter:                        $space;
$gutter-small:                  $space-two-thirds;
$gutter-large:                  $space-and-third;

//  Leading
// =========
//  Space between rows
$slug:                          $gutter;
$slug-small:                    $slug / 2;
$slug-smaller:                  $slug / 3;
$slug-large:                    $gutter-large;

$leading:                       $space-double;
$leading-small:                 $leading / 2;

//  Containers
// ============
$container-narrow:              900px;
$container-standard:            $full-width;
$container-wide:                1600px;
$container-inner:               760px;

//  Widget grid
// =============
//  Used to normalise the widget widths applied in
//  the `Homepage Editor`.
//  Convert
//  |   33%   |   33%   |   33%   |  |
//  into
//  | 33.3333% | 33.3333% | 33.3333% |
$widget-grid: (
    (1, 12),
    (13, 21),
    (22, 29),
    (30, 36),
    (37, 45),
    (46, 54),
    (55, 62),
    (63, 71),
    (72, 78),
    (79, 83),
    (84, 90)
);

// ========================================================




// ========================================================
//  Styling
// =========

//  Borders
// =========
$border-width-small:            1px;
$border-width-mid:              2px;
$border-width-large:            3px;
$border-width-x-large:          4px;
$border-width-xx-large:         5px;

//  Shadows
// =====================
// $shadow: 0 1px 2px rgba($color-black, .4);

// ========================================================




// ========================================================
//  Interactions
// ==============

//  Transitions
// =============
$bezier:                    cubic-bezier(.66, .3, .41, .99);
$transition-speed:          .3s;
$transition-speed-fast:     .2s;
$transition-speed-mid:      .5s;
$transition-speed-slow:     1s;

// ========================================================




// ========================================================
//  Output settings
// =================
$old-ie: false !default;
$includeAccessibilitySettings: true !default;
$accessibilityIconFallback: false !default;

// ========================================================


$arrow-size: $space-fifth * 2;

$border-width-thin:         $border-width-small;
$button-border-width:       2px;
$button-border-radius:      $space-quarter;
$border-width:              6px;
$border-width--hover:       $border-width * 2;
$border-width-thick:        $space-half;
$border-radius-form-field:  5px;

$boxed-offset: 15rem;
$image-offset: $boxed-offset + -$space-double;

$form-select-arrow: 'data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2270%22%20height%3D%2270%22%20viewBox%3D%220%200%2070%2070%22%20preserveAspectRatio%3D%22xMinYMid%22%20aria-hidden%3D%22true%22%3E%3Cpath%20d%3D%22M46.647%2C30.083%2C44.77%2C28.27%2C35%2C38.044%2C25.226%2C28.27l-1.873%2C1.813L35%2C41.73Z%22%2F%3E%3C%2Fsvg%3E';


// ========================================================
//  Debugging options
// ===================

//  Show baseline grid
// ====================
$show-baseline:                 false;

//  Show media queries
// ====================
$show-mq:                       false;

//  Enable debug mode
// ===================
//  Highlight possible mark-up / accessibility quirks
//  in your code.
//  Debug styles can be found in `helpers/_debug.scss`
$debug-mode:                    false;

//  Relative paths to folders
// ====================
$imageContentFolderPath:        '../images/';
$imageThemeFolderPath:          'images/';

/**
 * Global z-index control
 */
$global-z-index-control: (
    'modal':                    999,
    'modal-background':         998,
    'tooltip':                  997,
    'site-header':              996,
    'site-main':                995
);
