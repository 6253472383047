%button-arrow {
    border: $space-half solid transparent;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: border-color $transition-speed;
    z-index: 3;
}

%button-arrow--left {
    border-left-width: 0;
    border-right-color: $color-grey-light;
    left: -#{$space-third + $space-quarter};
}

%button-arrow--right {
    border-left-color: $color-grey-light;
    border-right-width: 0;
    right: -#{$space-third};
}
