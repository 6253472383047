.full-width-background,
%full-width-background {
    background-color: $color-grey-lighter;
    padding-bottom: $leading-small;
    padding-top: $leading-small;

    .supplement:first-child {
        margin-top: 0;
    }
}
