$top-task-count: 4;
$top-task-break: $small-width;
$top-task-break--wide: $large-width;
$top-task-offset: $gutter;
$top-task-icon-parent: 130px !default;
$top-task-icon-child: 60px !default;

.top-tasks {
    margin-left: -$top-task-offset;
}

.top-task {
    color: $color-primary-light;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;

    @include respond-min($top-task-break) {
        width: 50%;
    }

    @include respond-min($top-task-break--wide) {
        width: percentage(1 / $top-task-count);
    }
}

.top-task-parent {
    @extend %link-style;

    display: block;
    font-size: $epsilon;
    margin: 0 0 $top-task-offset $top-task-offset;
    opacity: 1;
    padding: $space-half $space-half $space-two-thirds;
    position: relative;
    transition: $transition-speed-fast opacity;
    z-index: 1;

    @include respond-min($top-task-break--wide) {
        margin-left: $top-task-offset;
    }

    .js & {
        min-height: $space-quadruple * 2; // Fixes issue with match height being called before icon is loaded
    }

    .icon {
        transition: $transition-speed-fast opacity;
    }

    &:hover,
    &:focus {
        .icon {
            opacity: .7;
        }
    }

    &[aria-expanded="true"] {
        &::before {
            border: $space-fifth solid $color-black;
            border-color: transparent transparent $color-white $color-white;
            bottom: -$space-third;
            box-shadow: -2px 3px 2px 0 darken($color-border, 1%);
            content: '';
            left: $space;
            position: absolute;
            transform: rotate(-45deg);
            transform-origin: 0 0;
        }
    }
}

.top-task-parent__icon {
    background-position: center top;
    background-size: contain;
    display: block;
    height: $top-task-icon-parent;
    margin-bottom: $space-half;
    width: 100%;
}

.top-task-parent__icon-inner {
    height: $top-task-icon-child;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: $space-double;
    width: $top-task-icon-child;
}

.top-task-child__wrap {
    display: none;
    left: 0;
    padding-left: $top-task-offset;
    position: relative;
    width: 100%;

    @include respond-min($top-task-break) {
        width: 200%;
    }

    @include respond-min($top-task-break--wide) {
        width: 400%;
    }

    &.is-active[aria-hidden='true'] {
        transition: none;
    }

    &[aria-hidden='false'],
    &.is-active[aria-hidden='true'] {
        display: block;
        margin-bottom: 0;
        max-height: 0;
        opacity: 0;
        transition: max-height $transition-speed $bezier, $transition-speed opacity, $transition-speed margin-bottom;
    }

    &.is-active {
        margin-bottom: $top-task-offset;
        opacity: 1;

        @include respond-min($desk-break) {
            margin-bottom: 0;
        }
    }

    &[aria-hidden='false'] {
        &::before {
            background: $color-off-white;
            box-shadow: inset 2px -2px 5px -3px rgba($color-black, .2);
            content: none;
            display: block;
            height: $arrow-size;
            left: $space;
            position: absolute;
            top: -$border-width-small;
            transform: rotate(-45deg);
            transform-origin: 0 0;
            width: $arrow-size;

            @include respond-min($top-task-break--wide) {
                content: '';
            }
        }
    }

    .list__item {
        position: relative;
        text-align: left;
        vertical-align: top;

        @include respond-min($top-task-break) {
            display: inline-block;
            padding: 0 $space;
            width: percentage(1 / 3);
        }
    }

    .list__link {
        display: inline-block;
        overflow: hidden;

        &:hover {
            .icon--large {
                opacity: .7;
            }
        }
    }

    .top-task:nth-child(even) & {
        @include respond-min($top-task-break) {
            left: -100%;
        }
    }

    @for $i from 1 through $top-task-count {
        .top-task:nth-child(#{$i}) & {
            @include respond-min($top-task-break--wide) {
                left: -#{($i * 100%) - 100%};
            }

            &[aria-hidden='false']::before {
                @include respond-min($top-task-break--wide) {
                    left: calc(#{$top-task-offset + $space} + 25% * #{$i - 1});
                }
            }
        }
    }
}

.top-task-child {
    margin: 0;
    padding: $space-two-thirds $space-and-two-thirds;

    .icon--large {
        float: left;
        opacity: 1;
        position: relative;
        transition: $transition-speed-fast opacity;
    }

    .icon--large-inner {
        @include position-center();
    }
}

.top-task-child__link {
    font-size: $epsilon;
    line-height: 1.2;
    padding-left: $space-two-thirds;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .list__link:hover & {
        text-decoration: underline;
    }
}
