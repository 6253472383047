@media print {
// scss-lint:disable ImportantRule
    html {
        color: $color-black !important;
        font-size: 100% !important;
    }

    body {
        margin: 0 auto;
        padding-top: 0;
    }

    html,
    body,
    .site-main {
        background-color: transparent !important;
    }

    a {
        color: $color-black !important;
    }

    .identity,
    .identity__logo {
        width: 100%;
    }

    .site-main {
        padding: $space 0 0;
    }

    .boxed {
        padding: 0;
    }

    .site-header__container {
        background-color: transparent;
        margin-bottom: $space;
        padding: 0;
    }

    .site-header .identity__heading {
        clip: auto;
        color: $color-black;
        font-size: $alpha;
        height: auto;
        position: relative;
        width: auto;
    }

    .site-header .site-header__navigation,
    .site-header .identity__icon,
    .site-search,
    .site-footer,
    .button,
    .breadcrumb,
    .sidebar--primary,
    .site-announcement,
    .pagination,
    .carousel__dots,
    .related-links,
    .share-page,
    .boxed--pages-in,
    .supplements,
    .widget--in-my-area,
    .widget--directory-search,
    .widget--opinion-poll {
        display: none !important;
    }

    .slick-track {
        transform: none !important;
        width: auto !important;
    }

    .carousel__slide {
        float: none !important;
        width: auto !important;
    }

    .flickity-viewport {
        height: auto !important;
        overflow: visible;
    }
// scss-lint:enable ImportantRule
}
