$identity-width: 16rem !default;
$identity-height: $space-triple !default;
$identity-width-mobile: 10rem !default;
$identity-height-mobile: $space-double !default;

$logo-fallback-width: 245px;
$logo-fallback-height: 75px;

.identity {
    float: left;
    width: $identity-width-mobile;

    @include respond-min($mobile-break) {
        padding-right: $space-half;
        width: 50%;
    }

    @include respond-min($tablet-break) {
        width: percentage(1 / 3);
    }
}

.identity__logo {
    display: block;
    height: $identity-height-mobile;
    margin-top: $space-half;
    max-height: 100%;
    max-width: 100%;
    opacity: 1;
    transition: opacity $transition-speed;
    width: $identity-width-mobile;

    @include respond-min($mobile-break) {
        height: $identity-height;
        margin-top: 0;
        width: $identity-width;
    }

    @include respond-min($tablet-break) {
        margin-top: $space-twelfth;
    }

    &:hover {
        opacity: .7;
    }
}

.identity__link {
    &:focus {
        .identity__logo {
            outline: $border-width-large solid $color-link-focus;
        }
    }
}

.identity__icon {
    svg {
        height: 100%;

        @include respond-min($mobile-break) {
            height: auto;
            width: 100%;
        }
    }

    .no-js &,
    &.identity__icon--a11y {
        display: block;
        height: $logo-fallback-height;
        width: $logo-fallback-width;
    }
}

.identity__image {
    display: block;
}

.identity__heading {
    @extend %visually-hidden;
}

