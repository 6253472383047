$table-caption-side: top !default;

.data-table,
%data-table {
    width: 100%;

    @if $table-caption-side == 'bottom' {
        caption-side: bottom;
    }

    caption {
        @extend %data-table__caption;
    }

    thead {
        background-color: $color-primary-light;
        color: $color-white;
    }

    tfoot {}

    tr {
        border-bottom: $border-width-small solid $color-text-muted;
    }

    th {
        @extend %normalis;

        padding: $space-half;
        text-align: left;
    }

    td {
        @extend %normalis;

        padding: $space-half;

        p {
            margin: 0;
        }
    }

    .button {
        &,
        .editor & {
            margin: $space-quarter;

            @include respond-max($x-small-width) {
                margin: 0;

                + .button {
                    margin-top: $space-half;
                }
            }
        }
    }
}

.data-table__button-wrap-column {
    @include respond-min($small-width) {
        white-space: nowrap;
    }
}

table {
    .site-content &:not([class]),
    .editor & {
        @extend %data-table;
        @extend %data-table--striped;
    }
}

%data-table__caption,
.data-table__caption {
    @if $table-caption-side == 'bottom' {
        margin-top: $slug-small;
    }
    @else {
        margin-bottom: $slug-small;
    }
}

.table--xfp {
    @extend %data-table;
    @extend %data-table--bordered;

    th {
        border-bottom-color: $color-primary;
    }
}
