.form--block {
    @extend %cf;

    @include respond-min($large-width) {
        .form__label {
            float: left;
            padding-right: $space-two-thirds;
            text-align: right;
            width: percentage(1 / 3);
        }

        .form__label--checkbox,
        .form__label--radio {
            float: none;
            text-align: left;
            width: 100%;
        }

        .form__control-group,
        .form__component {
            clear: none;
            float: right;
            position: relative;
            text-align: left;
            width: percentage(2 / 3);

            .form__component,
            .form__control-group {
                clear: both;
                float: none;
                width: 100%;
            }
        }

        .form__append-group {
            %form__field {
                float: none;
                width: 100%;
            }
        }

        %form__field,
        .form__field,
        .form__select,
        .form__textarea,
        .form__append-group {
            margin-top: 0;
            width: 100%;
        }

        .form__footer {
            padding-left: percentage(1 / 3);
        }
    }

    .data-table,
    .form__table,
    .table--xfp {
        .form__label,
        %form__field,
        .form__select,
        .form__textarea {
            display: block;
            float: none;
            margin-bottom: $space-quarter;
            margin-top: 0;
            text-align: left;
            width: 100%;
        }
    }
}
