.widget-gallery {
    .image {
        float: none;
        margin: auto;
        max-width: none;
        width: 100%;
    }

    .image__caption:empty {
        display: none;
    }

    .carousel__img {
        margin: 0 auto;
    }

    .carousel__figure {
        margin: 0;
    }

    .carousel__caption {
        margin-top: $space;
    }

    .no-js & .carousel__slides-wrapper {
        padding-bottom: $space;
    }
}
