// Aside
//
// .aside
// .aside--below
// .aside--sidebar

%aside,
.aside {
    @extend %cf;

    margin-top: $slug;
}

.aside__section,
%aside__section {
    @extend %boxed;

    margin-bottom: $space;

    > *:first-child {
        margin-top: 0;
    }
}

.aside__heading,
%aside__heading {
    @extend %boxed__heading;

    font-size: $epsilon;
    margin: 0 0 $space-two-thirds;
}

.aside__list,
%aside__list {
    margin-top: $space-half;

    .list__item {
        margin-top: $space-half;
    }
}

.aside__links,
%aside__links {
    margin-top: $space-half;

    .list__item {
        margin-top: $space-half;
    }
}

%aside--sidebar,
.aside--sidebar {
    @include respond-max($mid-width) {
        float: left;
        margin-top: 0;
        width: 100%;
    }

    .two-column & {
        @include respond-min($mid-width) {
            float: left;
            margin-top: 0;
            width: percentage(3.25 / 12);
        }
    }
}
