.list--social,
%list--social {
    .list__item {
        display: inline-block;
        opacity: 1;
        transition: $transition-speed-fast opacity;

        &:hover {
            opacity: .7;
        }
    }

    .list__link {
        display: block;
    }
}

.share-page {
    @include respond-min($mid-width) {
        text-align: right;
    }
}
