$high-contrast-bg:     $color-black;
$high-contrast-text:   #ff0;
$high-contrast-link:   #0f0;
$high-contrast-border: $high-contrast-text;

$cream-bg:             #fff9d2;
$cream-text:           #010066;
$cream-link:           #6f6200;
$cream-border:         $cream-text;

$blue-bg:              #9fcfff;
$blue-text:            #010066;
$blue-link:            #f00;
$blue-border:          $blue-text;

// scss-lint:disable ColorKeyword
$schemes: (
    high-contrast: ($high-contrast-bg,
                    $high-contrast-text,
                    $high-contrast-link,
                    $high-contrast-border),

    cream:         ($cream-bg,
                    $cream-text,
                    $cream-link,
                    $cream-border),

    blue:          ($blue-bg,
                    $blue-text,
                    $blue-link,
                    $blue-border)
);

// scss-lint:disable ImportantRule
@each $key, $colours in $schemes {

    $access-bg-color:       #{nth($colours, 1)};
    $access-text-color:     #{nth($colours, 2)};
    $access-link-color:     #{nth($colours, 3)};
    $access-border-color:   #{nth($colours, 4)};

    .user-style-scheme-#{$key} {
        &,
        * {
            background-color: $access-bg-color !important;
            border-color: $access-border-color !important;
            color: $access-text-color !important;
            outline-color: $access-link-color !important;
        }

        .icon,
        .icon-logo {
            background-color: transparent !important;
        }

        a {
            &,
            &:visited {
                color: $access-link-color !important;
            }

            &:active,
            &:focus,
            &:hover {
                color: $access-text-color !important;
            }

            &::before,
            &::after {
                background-color: $access-text-color !important;
                color: $access-bg-color !important;
            }
        }

        .list-social-links {
            .list__link {
                height: auto !important;
                width: auto !important;
            }

            .icon-fallback {
                @extend %normalis;

                color: $access-text-color !important;
            }
        }

        .list-skip-links .list__item {
            background-color: transparent !important;
        }

        .site-search .form__field {
            border: $border-width-small solid $access-border-color !important;
        }

        .site-search ::placeholder,
        .form__control ::placeholder {
            color: $access-text-color !important;
        }

        .site-search .button::before,
        .services__block .button::before {
            border-left-color: $access-border-color !important;
            border-right-color: $access-border-color !important;
        }

        .boxed,
        %boxed,
        .carousel-one-slide,
        .callout,
        .supplement,
        .widget.styles999,
        .directory-search,
        .widget-myaccount-signed-out-content,
        .widget-myaccount-sign-in,
        .widget-my-account-direct-debits,
        .widget-my-account-forms,
        .widget-myaccount-api-links,
        .widget-myaccount-form-submissions,
        .widget-myaccount-my-address,
        .widget-myaccount-paybridge-balance-lookup,
        .widget-myaccount-payments,
        .my-cases,
        .services__block,
        .sidebar__section {
            border: $border-width-small solid $access-border-color !important;
            border-radius: 0 !important;
            box-shadow: none !important;
        }

        .list__item,
        %list__item {
            &::before {
                background-color: $access-border-color !important;
            }
        }

        .services__title {
            background-color: transparent !important;
        }

        .slick-dots button::before {
            border-color: $access-border-color !important;
        }

        .slick-dots li.slick-active button::before {
            background-color: $access-border-color !important;
        }

        .download__cta {
            border: $border-width-small solid $access-border-color;
        }

        .download__heading,
        .download__cta {
            &:active,
            &:focus,
            &:hover {
                position: relative; // bring focus outline to front
            }
        }

        .button {
            .nav-toggle__line {
                background-color: $access-text-color !important;
                border-color: $access-border-color !important;
                color: $access-bg-color !important;
            }

            &,
            * {
                transition-duration: 0s !important;
            }

            &::after {
                visibility: hidden !important;
            }

            &:active,
            &:focus,
            &:hover {
                &,
                * {
                    border-color: $access-link-color !important;
                    color: $access-link-color !important;
                }
            }
        }

        .widget-top-tasks {
            .top-task-parent__icon {
                svg {
                    visibility: hidden !important;
                }
            }

            svg,
            path {
                fill: $access-text-color !important;
            }
        }

        .widget-top-tasks-simple {
            .icon-large {
                background-image: none !important;

                svg,
                path {
                    fill: $access-text-color !important;
                }
            }
        }

        .services__block,
        .in-my-area__button-group {
            .icon svg {
                background-color: transparent !important;
            }
        }

        .in-my-area {
            .form,
            .form__control,
            .form__append-group,
            .form__label {
                background-color: transparent !important;
            }
        }

        .top-task {
            .top-task-parent__icon-inner,
            svg {
                background-color: transparent !important;
            }
        }

        .menu-navigation,
        .bookmarks {
            > .navigation {
                border-left-style: solid;
                border-left-width: $border-width-small;
            }
        }

        .map * {
            background-color: transparent !important;
        }

        .list-az .button {
            &.is-active {
                background-color: $access-text-color !important;
                color: $access-bg-color !important;
            }

            &.button-disabled {
                background: $color-disabled !important;
            }
        }

        .grid,
        .grid__cell {
            background-color: transparent !important;
        }

        .alert {
            border-width: $border-width-small;

            @include respond-min($small-width) {
                margin-left: $alertIconSize + $space-and-half;
            }
        }

        .services__block {
            border-radius: $space-quarter 0 0 $space-quarter;

            .button {
                border: 0;

                &::before {
                    display: none;
                }
            }
        }

        .site-announcement {
            border-bottom: $border-width-small solid $access-text-color !important;
            border-left: 0;
            border-right: 0;
            border-top: $border-width-small solid $access-text-color !important;
            padding-bottom: $space;
            padding-top: $space;
        }

        .calendar__date-today {
            background-color: $access-text-color !important;
            color: $access-bg-color !important;
        }

        .form__label em {
            color: $access-link-color !important;
        }

        .site-main {
            border-top: $border-width-small solid $access-text-color !important;
            margin-top: $space-half;
            padding-top: $space;
        }

        .site-footer__top,
        .site-footer__content {
            border-top: $border-width-small solid $access-text-color !important;
        }

        .visited-page .visited-page__toggle {
            max-width: 100%;
        }

        .visited-pages__list .list__item {
            .visited-page__toggle-icon {
                @extend %visually-hidden;
            }

            .visited-page,
            .visited-page__toggle {
                border: $border-width-small solid $access-text-color !important;
            }

            .visited-page-placeholder {
                border: 0 !important;
            }
        }

        %form__field:focus {
            outline: $border-width-mid solid $access-link-color;
        }

        // CXM calendar
        .calendar-event-booking {
            .button-disabled {
                background-color: $color-disabled !important;
                border: 0;
                color: $color-black !important;
            }

            .calendar__dates .form__radio + .button {
                border: $border-width-mid solid $color-black !important;

                &:hover,
                &:focus {
                    border-color: $access-link-color !important;
                }
            }

            .calendar__dates .form__radio:checked + .button {
                background-color: $access-text-color !important;
                color: $color-black !important;
            }
        }
    }
}


.user-style-size-small {
    font-size: 107% !important;
}

.user-style-size-medium {
    font-size: 114% !important;
}

.user-style-size-large {
    font-size: 121% !important;
}

.user-style-font-courier * {
    &,
    .user-style & {
        font-family: Courier, 'Courier New', monospace !important;
    }
}

.user-style-font-arial * {
    &,
    .user-style & {
        font-family: Arial, Helvetica, sans-serif !important;
    }
}

.user-style-font-times * {
    &,
    .user-style & {
        font-family: Times, 'Times New Roman', serif !important;
    }
}

.user-style-font-standard * {
    &,
    .user-style & {
        font-family: $font-base !important;
    }
}

.user-style-spacing-wide,
.user-style-spacing-wide input,
.user-style-spacing-wide textarea,
.user-style-spacing-wide button,
.user-style-spacing-wide select {
    &,
    .user-style & {
        letter-spacing: .15em !important;
    }
}

.user-style-spacing-wider,
.user-style-spacing-wider input,
.user-style-spacing-wider textarea,
.user-style-spacing-wider button,
.user-style-spacing-wider select {
    &,
    .user-style & {
        letter-spacing: .3em !important;
    }
}

.user-style-spacing-widest,
.user-style-spacing-widest input,
.user-style-spacing-widest textarea,
.user-style-spacing-widest button,
.user-style-spacing-widest select {
    &,
    .user-style & {
        letter-spacing: .45em !important;
    }
}

.user-style-spacing-standard,
.user-style-spacing-standard input,
.user-style-spacing-standard textarea,
.user-style-spacing-standard button,
.user-style-spacing-standard select {
    &,
    .user-style & {
        letter-spacing: 0 !important;
    }
}

@each $key, $colours in $schemes {
    $access-bg-color:       #{nth($colours, 1)};
    $access-text-color:     #{nth($colours, 2)};
    $access-link-color:     #{nth($colours, 3)};
    $access-border-color:   #{nth($colours, 4)};

    .user-style .user-style-scheme-#{$key} {
        background-color: $access-bg-color !important;
        border-color: $access-border-color;

        h3 {
            color: $access-text-color !important;
        }

        p {
            color: $access-text-color !important;
        }

        a {
            color: $access-link-color !important;

            &:hover,
            &:focus,
            &:active {
                background-color: transparent !important;
                color: $access-border-color !important;
            }
        }
    }
}

.user-style .user-style-scheme-standard {
    background-color: $color-white !important;
    border-color: $color-primary;

    h3 {
        color: $color-text-default !important;
    }

    p {
        color: $color-text-default !important;
    }

    a {
        color: $color-link !important;

        &:hover,
        &:focus,
        &:active {
            color: $color-link-hover !important;
        }
    }
}
// scss-lint:enable ImportantRule

// scss-lint:disable VendorPrefix
.alert::before,
.icon {
    .user-style-scheme-high-contrast & {
        -webkit-filter: url('#filter_high-contrast');
        filter: url('#filter_high-contrast');
    }

    .user-style-scheme-cream & {
        -webkit-filter: url('#filter_cream');
        filter: url('#filter_cream');
    }

    .user-style-scheme-blue & {
        -webkit-filter: url('#filter_blue');
        filter: url('#filter_blue');
    }
}

.widget-top-tasks-simple .icon {
    .user-style-scheme-high-contrast & {
        -webkit-filter: none;
        filter: none;
    }

    .user-style-scheme-cream & {
        -webkit-filter: none;
        filter: none;
    }

    .user-style-scheme-blue & {
        -webkit-filter: none;
        filter: none;
    }
}

.feedback__button::before {
    .user-style-scheme-high-contrast & {
        background-color: $high-contrast-text;
    }
}

.form__select {
    .user-style-scheme-high-contrast & {
        background-image: url(#{$form-select-arrow}), linear-gradient(hsla(60, 100%, 50%, 1), hsla(60, 100%, 50%, 1));
    }

    .user-style-scheme-cream & {
        background-image: url(#{$form-select-arrow}), linear-gradient(hsla(52, 100%, 91%, 1), hsla(52, 100%, 91%, 1));
    }

    .user-style-scheme-blue & {
        background-image: url(#{$form-select-arrow}), linear-gradient(hsla(210, 100%, 81%, 1), hsla(210, 100%, 81%, 1));
    }
}
// scss-lint:enable VendorPrefix
