// ========================================================
//  Item Lists
// ========================================================

//  Base style
// ============
$list-item-spacing: $space-hard !default;
$list-line-height: 1.3 !default;

%list--child {
    margin-left: $gutter-large;
}


.list,
%list {
    list-style: none;
    margin-left: 0;

    time {
        display: block;
        font-size: $milli;
        margin: $space-sixth 0 $slug;
    }
}

.list__item,
%list__item {
    margin-top: $space-third;

    &:first-child {
        $margin-top: 0;
    }
}

.list__link,
%list__link {
    @extend %link-style;
}

.list__note {
    @extend %micro;
}

.list__link--disabled {
    color: $color-text-default;
}
