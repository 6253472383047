// Bookmarks container block
// =========================
.visited-pages {
    .no-js & {
        display: none;
    }

    .navigation {
        background-color: $color-teal-dark;
        padding-top: $space-two-thirds;
    }

    .navigation__button {
        @include button-slide($color-teal-dark);

        &,
        &:visited,
        &:hover,
        &:focus {
            border-color: $color-white;
        }
    }

    .list__item {
        transform: translateX(75%);
        transition: $transition-speed transform;

        &.is-animated {
            transform: translateX(0);
        }
    }
}

.visited-pages__heading,
%visited-pages__heading {
    color: $color-white;
    font-size: $zeta;
    margin-bottom: $space-and-half;
}

.visited-pages__subheading {
    @extend %visited-pages__heading;

    margin-bottom: $space-quarter;
}

.visited-pages__summary {
    font-size: $milli;
    line-height: 1.5;
    margin-top: 0;
}

.visited-pages__subheading-link {
    &,
    &:visited {
        color: $color-white;
        text-decoration: underline;
    }

    &:hover,
    &:focus {
        color: $color-white;
        text-decoration: none;
    }
}


// Individual Bookmark block
// =========================
.visited-page {
    background-color: $color-primary;
    display: flex;
    padding-left: $space-half;

    &.is-pinned {
        background-color: $color-grey-light;
    }
}

.visited-page__link {
    float: left;
    margin: $space-half ($space-and-third * 2) $space-half $space-quarter;
    width: 100%;
}

.visited-page__toggle {
    background-color: $color-primary-light;
    border: 0;
    border-radius: 0;
    bottom: 0;
    max-width: $space-double;
    padding: $space-third;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
        background-color: $color-primary;
    }

    &:focus {
        outline: $border-width-large solid $color-link-focus;
    }

    .is-pinned & {
        background-color: $color-white;

        &:hover {
            background-color: $color-grey-light;
        }

        .js & {
            &::after {
                background-color: $color-grey;
                left: 0;
            }
        }
    }
}

.visited-page__toggle-icon {
    @extend .icon;
}

.visited-page__heading,
.visited-page__category {
    color: $color-white;

    .is-pinned & {
        color: $color-primary;
    }
}

.visited-page__heading {
    font-size: $zeta;
    margin: 0;
}

.visited-page__category {
    font-size: $milli;
    margin: 0;
}

.visited-pages__accept {
    background-color: transparent;
    border: 0;
    color: $color-white;
    margin: 0;
    padding: 0;
    text-decoration: underline;
}

.toggle-target[aria-hidden="true"] {
    display: none;
}
