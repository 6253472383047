$button-arrow-width: $space-two-thirds;
$button-arrow-height: $button-arrow-width;
$button-arrow-padding: $space-double - $button-arrow-width;

.button,
%button {
    &,
    &:visited {
        @extend %normalis;

        background-color: $color-pink;
        border: $button-border-width solid $color-pink;
        border-radius: $space-quarter;
        color: $color-white;
        cursor: pointer;
        display: inline-block;
        font-family: $font-base;
        font-weight: $font-weight-normal;
        margin: 0;
        overflow: hidden;
        padding: $space-half $space-double;
        position: relative;
        text-align: center;
        text-decoration: none;
        transition: $transition-speed background-color, $transition-speed border-color, $transition-speed color;
        vertical-align: middle;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: $color-link;
        color: $color-white;
        text-decoration: none;
    }

    &:focus,
    &:active {
        @include focus-outline;
    }

    &[disabled] {
        cursor: not-allowed;
    }
}

.button__inner {
    display: block;
    position: relative;
    z-index: 2;
}

p + .button {
    margin-top: $space;
}

.button-group,
%button-group {
    margin-bottom: $leading - $space-three-quarters;
    margin-top: $leading;

    .button-group__title {
        display: inline-block;
        font-weight: $font-weight-bold;
        margin-right: $gutter;
        padding: $space-half 0;
        vertical-align: top;
    }

    .button {
        margin-bottom: $space-three-quarters;
        vertical-align: top;

        @include respond-max($small-width) {
            display: block;
            margin-right: 0;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        @include respond-min($small-width) {
            margin-right: $gutter;
        }
    }
}

.button-wrapper {
    margin-bottom: $leading;
}

// Payments
.basket {
    .button {
        display: block;
        width: 100%;
    }

    .button + .button {
        margin-top: $space-half;
    }
}

// Print button
.print-button {
    @extend %link-style;

    background-color: transparent;
    border: 0;
    padding-bottom: 0;
    padding-top: 0;

    @include respond-max($mid-width) {
        padding: 0;
    }

    &:hover {
        cursor: pointer;
    }
}
