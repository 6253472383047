$in-my-area-icon-height: $space-quadruple + $space-two-thirds;
$in-my-area-icon-width: $in-my-area-icon-height;
$allow-space-for-icon: $space;

.in-my-area-results {
    @extend %boxed;
    @extend %boxed--border-primary;
    @extend %boxed--flush;
}

.in-my-area-results__map-wrapper {
    margin-bottom: $leading;
}

// scss-lint:disable SelectorFormat
// Uneditable classes from map include
.mapLayerSelection {
    @extend %form;

    label {
        @extend %form__label;
        @extend %form__label--checkbox;
    }

    input {
        @extend %form__checkbox;
    }
}
// scss-lint:enable SelectorFormat
