// Announcements and Tracked URLs
// Announcements and Tracked URLs
$announcement-icon-width: 3.5em;
$announcement-icon-height: $announcement-icon-width;

.site-announcement {
    background-color: $color-yellow;

    &.site-announcement--welcome {
        background-color: $color-primary;
    }
}

.site-announcement__content {
    padding-bottom: 0;
    padding-left: $gutter;
    padding-right: calc(#{$announcement-icon-width} + #{$gutter});
    padding-top: $space-third;
    position: relative;

    &::before {
        @include position-center-y();

        background-size: cover;
        content: '';
        height: $announcement-icon-height;
        right: $gutter;
        width: $announcement-icon-width;
    }

    .site-announcement--welcome & {
        padding-top: $space-sixth;

        &::before {
            display: none;
        }
    }
}

.site-announcement__heading {
    font-size: $epsilon;
    font-weight: $font-weight-bold;
    margin: 0 0 $space-twelfth;

    .site-announcement--welcome & {
        color: $color-white;
    }
}

.site-announcement__text {
    font-weight: $font-weight-bold;
    margin-top: 0;

    .site-announcement--welcome & {
        color: $color-white;
    }
}

.site-announcement__link {
    color: $color-text-default;

    .site-announcement--welcome & {
        color: $color-white;
        text-decoration: underline;
    }
}

