/// Largest font size
%giga,
.giga {
    font-size: $giga;
    line-height: 1.2;
    margin: $space-half 0;
}

/// Second largest font size
%mega,
.mega {
    font-size: $mega;
    line-height: 1.2;
    margin: $space-half 0;
}

/// Third largest font size
%kilo,
.kilo {
    font-size: $kilo;
    line-height: 1.2;
    margin: $space 0 $space-third;
}

/// Fourth largest font size
%alpha,
.alpha {
    font-size: $alpha;
    line-height: 1.2;
    margin: $space 0 $space-third;
}

/// Fifth largest font size
%beta,
.beta {
    font-size: $beta;
    line-height: 1.3;
    margin: $space-two-thirds 0 $space-quarter;
}

/// Sixth largest font size
%gamma,
.gamma {
    font-size: $gamma;
    line-height: $line-height-ratio;
    margin: $space-two-thirds 0 0;
}

/// Seventh largest font size
%delta,
.delta {
    font-size: $delta;
    line-height: $line-height-ratio;
    margin: $space-two-thirds 0 0;
}

/// Eighth largest font size
%epsilon,
.epsilon {
    font-size: $epsilon;
    line-height: 1.3;
    margin: $space-half 0 0;
}

/// Ninth largest font size
%zeta,
.zeta {
    font-size: $zeta;
    line-height: $line-height-ratio;
    margin: $space-third 0 0;
}

/// Tenth font size
%normalis,
.normalis {
    font-size: $normalis;
    line-height: $line-height-ratio;
    margin: $space-half 0 0;
}

/// Eleventh largest font size
%milli,
.milli {
    font-size: $milli;
    line-height: $line-height-ratio;
    margin: $space-half 0 0;
}

/// Twelfth largest font size
%micro,
.micro {
    font-size: $micro;
    line-height: $line-height-ratio;
    margin: $space-two-thirds 0 0;
}

//  Base Type Reset
// =================
%type-reset {
    font-family: $font-family;
    font-size: $normalis;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: $line-height-ratio;
    margin: $space-half 0 0;
}


/*  Headings
\*----------------------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-text-default;
    font-weight: $font-weight-bold;
}

h1 {
    @extend %kilo;

    text-align: center;
}

h2 {
    @extend %alpha;
}

h3 {
    @extend %beta;
}

h4 {
    @extend %gamma;
}

h5 {
    @extend %delta;

    font-weight: $font-weight-normal;
}

h6 {
    @extend %zeta;
}

// Header Pairings
h1 + h2 {
    margin-top: 0;
    text-align: center;
}

h2 + h3 {
    margin-top: 0;
}

@for $i from 1 through 6 {
    %h#{$i},
    .h#{$i} {
        @extend h#{$i};

        display: block;
    }
}

code {
    line-height: 1;
}

pre {
    @extend %milli;

    margin-top: 0;
    tab-size: 2;

    + p {
        margin-top: $space;
    }
}

p {
    margin: $space 0 $space-quarter;
}

.summary,
%summary {
    @extend %epsilon;

    margin: $space 0 $space-and-half;
}

.summary--border {
    border-bottom: $border-width-mid solid $color-border;
    padding-bottom: $space;
}

// Appears after an input or button, describing it's action
.help {
    @extend %normalis;

    font-weight: normal;
    margin-bottom: $space-half;
    margin-top: $space-quarter;
}
