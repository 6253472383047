
.top-tasks-simple {
    padding-top: $gutter-small;

    @include respond-min($mobile-break) {
        padding-top: 0;
    }

    .top-task-simple__block {
        padding-bottom: $gutter-small;

        @include respond-min($mobile-break) {
            padding-bottom: 0;
            width: 50%;

            &:nth-child(2n+1) {
                clear: left;
            }
        }

        @include respond-min($tablet-break) {
            &:nth-child(2n+1) {
                clear: none;
            }

            &:nth-child(3n+1) {
                clear: left;
            }
        }

        .icon--large {
            float: left;
            opacity: 1;
            position: relative;
            transition: $transition-speed-fast opacity;
        }

        .icon--large-inner {
            @include position-center();
        }
    }

    .top-task-simple__link {
        @extend %link-style;

        display: block;
        overflow: hidden;
        position: relative;

        &:hover {
            .icon--large {
                opacity: .7;
            }

            .top-task-simple__title {
                text-decoration: underline;
            }
        }
    }

    .top-task-simple__title {
        font-size: $epsilon;
        line-height: 1.2;
        padding-left: $space-two-thirds;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

}

.widget-width--medium,
.widget-width--small {
    // Prevent grid overriding responsive view
    .grid__cell {
        width: 100%;
    }
}
