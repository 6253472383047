.list--az {
    @extend %cf;

    display: block;
    margin-left: $space-half * -1;

    .list__item {
        display: inline-block;
        margin: 0;
        padding: 0 0 $space-half $space-half;
        text-align: center;
        width: percentage(1 / 4);

        @include respond-min($x-small-width) {
            width: percentage(1 / 6);
        }

        @include respond-min($small-width) {
            width: percentage(1 / 10);
        }

        @include respond-min($mid-width) {
            width: percentage(1 / 13);
        }
    }

    .button {
        @extend %epsilon;

        display: block;
        margin: 0;
        padding: percentage(1 / 3.5) $space-half;
        width: auto;

        @include respond-min($small-width) {
            padding: percentage(1 / 5) $space-half;
        }

        @include respond-min($mid-width) {
            padding: percentage(1 / 4) $space-half;
        }

        .one-column & {
            @include respond-min($full-width) {
                padding: percentage(1 / 7) 0;
            }
        }

        &.is-active {
            background: $color-primary;
            border-color: $color-primary;
        }
    }
}
