// Homepage banner widget
.widget-homepage-banner {
    @extend %boxed;
    @extend %boxed--flush;

    .widget-row .widget & {
        overflow: hidden;
        padding: 0;
        position: relative;
    }

    .image__caption {
        background: rgba($color-primary, .9);
        bottom: 0;
        color: $color-white;
        display: block;
        left: 0;
        padding: $space-three-quarters $space;
        position: absolute;
        width: 100%;
    }

    .image-link {
        &:hover,
        &:focus,
        &:active {
            .image__caption {
                color: $color-white;
                text-decoration: underline;
            }
        }
    }
}
