.form__label--radio {
    @extend %form__label--checkbox;

    &::before,
    &::after {
        border-radius: 50%;
    }
}

.form__radio {
    @extend %form__checkbox;

    &[checked],
    &:checked {
        ~ .form__label {
            &::after {
                border-width: $border-width-xx-large;
            }
        }
    }
}
