.callout,
%callout {
    @extend %boxed;

    border: $border-width-mid solid $color-border;
    box-shadow: none;
    padding: $space;

    .image--thumbnail {
        float: right;
        margin: 0 0 $space-half $space-quarter;
        width: percentage(1 / 4);
    }
}

.callout__link {
    color: inherit;
    font-weight: bold;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.callout__heading {
    @extend %boxed__heading;
}

.callout__subheading {
    @extend %boxed__subheading;
}
