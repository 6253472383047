$form-field-text-colour: $color-text-default !default;

%form__select,
.form__select {
    @extend %form__field;

    appearance: none;
    background-image: url(#{$form-select-arrow}), linear-gradient(hsla(0, 0, 93%, 100), hsla(0, 0, 93%, 100));
    background-position: right center;
    background-repeat: repeat-y;
    background-size: $space-double;
    color: $color-primary;
    cursor: pointer;
    height: #{$space-half + $epsilon * $line-height-ratio + $space-half};
    line-height: $line-height-ratio;
    padding-right: $space-double + $space-sixth;

    &::-ms-expand {
        display: none;
    }

    &:hover {
        background-image: url(#{$form-select-arrow}), linear-gradient(hsla(0, 0, 95%, 100), hsla(0, 0, 95%, 100));
    }

    + .button {
        margin-top: $space-half;
    }
}
