// ========================================================
//  Content
// ========================================================
//  This file is intentionally sparse and are intended as
//  placeholder for your content styles that aren’t covered
//  by the objects in /sass/objects/. Orbit contains the
//  following content styles as default, feel free to
//  change as needed.

.site {
    min-height: 100vh;
    position: relative;

    @include respond-min($mid-width) {
        display: flex;
        flex-direction: column;
    }

    @include if-ie(ie) {
        display: block;
    }
}

.site-main {
    background-color: $color-off-white;
    padding: $leading 0 0;

    > .container {
        padding-bottom: $leading;
    }
}

.site-content {
    @extend %cf;

    margin: 0 auto;
}

.page-content {
    .homepage-modular &,
    .inner & {
        @include respond-min($mid-width) {
            float: left;
            padding-right: $space-and-half;
            width: percentage(8.5 / 10);
        }

        @include respond-min($large-width) {
            padding-right: $space * 3;
        }

        @include respond-min($x-large-width) {
            width: percentage(7 / 10);
        }
    }

    > *:first-child {
        margin-top: 0;
    }
}

.page-heading {
    margin: 0 0 $space;
    text-align: center;
}

.section-heading {
    @extend %kilo;

    margin: 0 0 $space;
    text-align: center;
}

// scss-lint:disable IdSelector
.document-preview {
    //have to use ID here as we can not amend the markup on the preview script
    #toolbar .button {
        margin: $space $space-half 0 0;
    }
}
// scss-lint:enable IdSelector

