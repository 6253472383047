// Paginator navigation
//
// Pagination style navigation

$pagination-icon-position-x: 0;
$pagination-icon-position-y: 8px;

.pagination {
    @extend %module;

    clear: both;
    display: block;
    margin: $space-and-three-quarters 0 $space-double;
    padding: 0;
    text-align: center;
}

.pagination__list {
    @extend %cf;
    @extend %list;

    margin: 0;
}

.pagination__item {
    display: inline-block;
}

.pagination__link {
    @extend %link-style;

    &:active,
    &:focus {
        background-color: $color-grey-lighter;

        .pagination__meta {
            color: $color-link-hover;
        }
    }

    &.is-active {
        &,
        &:hover,
        &:active {
            &,
            .pagination__meta,
            .pagination__label {
                background: transparent;
                box-shadow: none;
                color: $color-disabled;
                text-decoration: none;
            }
        }
    }
}

.pagination__link--disabled {
    &,
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;

        .pagination__meta,
        .pagination__label {
            color: $color-text-disabled;
            text-decoration: none;
        }
    }

    &.pagination__link {
        &,
        &:hover {
            .pagination__icon.icon {
                background-color: $color-disabled;
            }
        }
    }
}

.pagination__label {
    @extend %normalis;
    @include font-smoothing();

    color: $color-off-black;
    display: block;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    margin: 0;
}

.pagination__icon.icon {
    background-color: $color-secondary;
    transition: background-color $transition-speed-fast ease;

    .pagination__link:hover & {
        background-color: $color-primary;
    }
}

.pagination__type {
    @include respond-max($mid-width) {
        display: none;
    }
}

.pagination__meta {
    color: $color-primary-light;
    display: inline;

    @include respond-max($small-width) {
        display: none;
    }
}

.pagination__page-number {
    @include respond-max($small-width) {
        display: none;
    }
}

.pagination__item--previous {
    @extend %link-cta--prev;

    float: left;
    padding-right: $space;
    text-align: left;
    width: 50%;

    .pagination__link {
        @extend %link-cta__link;

        padding-left: $delta + $space-third;

        &:link,
        &:visited,
        &:hover,
        &:focus,
        &:active {
            color: $color-link-hover;
        }
    }

    .pagination__icon {
        left: $pagination-icon-position-x;
        top: $pagination-icon-position-y;

        @include respond-max($mobile-break) {
            top: $space-sixth;
        }
    }
}

.pagination__item--next {
    @extend %link-cta--prev;

    float: right;
    padding-left: $space;
    text-align: right;
    width: 50%;

    .pagination__link {
        @extend %link-cta__link;

        padding-right: $delta + $space-third;

        &:link,
        &:visited,
        &:hover,
        &:focus,
        &:active {
            color: $color-link-hover;
        }
    }

    .pagination__icon {
        right: $pagination-icon-position-x;
        top: $pagination-icon-position-y;

        @include respond-max($mobile-break) {
            top: $space-sixth;
        }
    }
}

// VARS
$complex-pagination-breakpoint: $mid-width !default;
$pagination-item-max-width: 9.5rem !default;

.pagination--complex {
    .pagination__item--previous,
    .pagination__item--next {
        @include respond-min($complex-pagination-breakpoint) {
            max-width: $pagination-item-max-width;
        }
    }

    .pagination__item--previous,
    .pagination__item--next {
        @include respond-min($complex-pagination-breakpoint) {
            margin-top: 0;
        }
    }

    .pagination__type {
        @extend %visually-hidden;
    }

    .pagination__item {
        display: inline-block;

        .pagination__link {
            background-color: transparent;
            border-radius: $button-border-radius;
            color: $color-primary;
            font-weight: $font-weight-bold;
            padding: $space-quarter $space-third;
            transition: background-color $transition-speed-fast, color $transition-speed-fast;

            &:hover,
            &:focus,
            &:active {
                background-color: $color-primary;
                color: $color-white;
            }

            &.is-active {
                color: $color-text-disabled;

                &:hover,
                &:focus,
                &:active {
                    background-color: inherit;
                    color: inherit;
                }
            }
        }

        .pagination__active {
            color: $color-text-disabled;
            display: block;
            padding: $space-quarter $space-third;
        }
    }
}
