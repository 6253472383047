// scss-lint:disable SelectorFormat
.search-list {
    // Overriding google's styling
    .gcsc-branding {
        display: none;
    }

    .gsc-control-cse,
    .gsc-control-cse .gsc-table-result {
        font-family: $font-base;
    }

    .gsc-above-wrapper-area-container {
        margin-bottom: 0;
    }

    .gsc-resultsHeader {
        margin: 0;
    }

    .gsc-above-wrapper-area {
        border-bottom: 0;
        margin-bottom: $space;
    }

    .gsc-selected-option-container.gsc-inline-block {
        background-image: none;
        box-shadow: none;
    }

    .gsc-table-result {
        margin: $space-sixth 0 0;
    }

    .gsc-result-info-container {
        vertical-align: bottom;
    }

    .gsc-orderby-container {
        padding-left: $space-quarter;
    }

    .gsc-result-info {
        color: $color-text-default;
        font-size: $milli;
        padding: 0;
    }

    .gsc-result,
    .gsc-webResult.gsc-result {
        border-bottom: $border-width-thin solid $color-border;
        padding: $space 0;

        &:hover,
        &:focus,
        &:active {
            border-bottom: $border-width-thin solid $color-border;
        }

        &:nth-last-child(3) {
            border-bottom: 0;

            &:hover,
            &:focus,
            &:active {
                border-bottom: 0;
            }
        }
    }

    .gsc-control-cse,
    .gsc-table-result,
    .gsc-thumbnail-inside,
    .gsc-url-top {
        padding: 0;
    }

    .gsc-url-top {
        margin-top: 0;
    }

    .gsc-thumbnail {
        display: none;
    }

    .gs-webResult.gs-result {
        .gs-title,
        .gs-title b {
            font-size: $delta;
            height: auto;
            margin-bottom: $space-third;
            overflow: visible;
            padding: 0;
            text-decoration: none;
        }

        .gs-title,
        a.gs-title:link {
            color: $color-link;

            b {
                color: $color-primary;
                font-weight: $font-weight-bold;
                transition: text-decoration-color $transition-speed-mid;
            }

            &:hover,
            &:active,
            &:focus {
                color: $color-link;
                text-decoration: underline;

                b {
                    color: $color-link;
                    text-decoration: none;
                }
            }

            &:visited {
                color: $color-link-visited;

                b {
                    color: $color-link-visited;
                }
            }
        }
    }

    .gs-webResult div.gs-visibleUrl,
    .gs-imageResult div.gs-visibleUrl {
        color: mix($color-black, $color-white, 60%);
    }

    .gs-webResult div.gs-per-result-labels a.gs-label {
        color: $color-link;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            color: $color-link;
            text-decoration: underline;

        }
    }

    .gsc-control-cse .gsc-table-result {
        color: $color-text-default;
        font-size: $normalis;
    }

    .gsc-cursor-box,
    .gsc-results .gsc-cursor-box {
        margin: $space-double 0 $space;
        position: relative;
        text-align: center;
    }

    .gsc-cursor {
        display: block;
    }

    .gsc-cursor-page,
    .gsc-results .gsc-cursor-box .gsc-cursor-page {
        border: $border-width-thin solid $color-border;
        color: $color-black;
        counter-increment: list_item_count;
        display: inline-block;
        font-size: $normalis;
        font-weight: $font-weight-normal;
        margin: $space-twelfth;
        min-height: $space;
        padding: $space-quarter $space-half;

        &:focus {
            outline: none;
        }

        &:hover,
        &:focus {
            background-color: $color-secondary;
            color: $color-white;
            text-decoration: none;
        }

        &.gsc-cursor-current-page {
            background-color: $color-primary;
            color: $color-white;

            &:hover,
            &:focus {
                background-color: $color-primary;
                color: $color-white;
            }
        }
    }

    .gs-no-results-result {
        .gs-snippet {
            background-color: transparent;
            border: 0;
            font-size: $zeta;
            margin: 0;
            padding: 0;
        }
    }

    .gsc-tabHeader.gsc-tabhActive {
        border-bottom: 0;
        border-color: $color-grey-light;
    }

    .gsc-tabsArea {
        border-color: $color-grey-light;
    }

    .gsc-tabHeader.gsc-tabhInactive {
        background-color: $color-grey-light;
        border-color: $color-grey-light;
        color: $color-white;
    }

    .gsc-result.gsc-promotion,
    .gsc-webResult.gsc-result.gsc-promotion {
        background-color: $color-grey-light;
        border: 0;
        padding: $space;

        .gs-title {
            color: $color-primary-dark;
            height: auto;
            margin-bottom: $space-quarter;

            * {
                color: $color-primary-dark;
            }
        }

        .gs-visibleUrl,
        .gs-visibleUrl-short {
            color: $color-text-default;
            margin-top: $space-third;
        }

        .gs-snippet {
            font-size: $milli;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $color-grey-light;
            border: 0;
        }
    }

    .gs-promotion a.gs-title:link,
    .gs-promotion a.gs-title:link *,
    .gs-promotion .gs-snippet a:link {
        color: $color-primary-dark;
        font-size: $zeta;
        height: auto;
        transition: box-shadow $transition-speed, color $transition-speed, text-decoration-color $transition-speed;

        &,
        &:visited {
            color: $color-primary-dark;
        }

        &:hover,
        &:active,
        &:focus {
            color: $color-black;
            text-decoration-color: $color-secondary;

            * {
                text-decoration-color: $color-secondary;
            }
        }
    }
}
// scss-lint:enable SelectorFormat
