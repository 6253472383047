// lists
$list-indent: $space;
$bullet-size: remToPx($space-third);
$bullet-space: $space-half;
$bullet-color: $color-primary-light;

.list--bullet,
%list--bullet {
    list-style: none;

    .list__item,
    %list__item {
        margin-top: $space-third;
        padding-left: $list-indent;

        &::before {
            background: $bullet-color;
            border-radius: 50%;
            content: '';
            display: inline-block;
            float: left;
            height: $bullet-size;
            margin-left: -$list-indent;
            margin-right: $bullet-space;
            margin-top: $space-hard;
            min-height: $bullet-size;
            min-width: $bullet-size;
            text-align: right;
            width: $bullet-size;

            @include respond-min($desk-break) {
                border-width: $border-width-xx-large;
            }
        }
    }

    .list__item--child,
    %list__item--child {
        &::before {
            background-color: mix($bullet-color, $color-white, 75%);
        }
    }
}
