// Opinion poll widget
.widget-poll {
    @extend %boxed;
    @extend %boxed--border-primary;
    @extend %boxed--flush;

    .form {
        margin-top: $space-half;
    }

    .form__footer {
        border-top: 0;
        padding: 0;
    }
}
