$translateBannerIframeHeight: 39px !default;
$googleTranslateDropdownMaxWidth: 17em !default; // Roughly the size of "Chinese (Traditional)" - the longest string
$googleTranslateDropdownMaxWidthSmall: $icon-size--inline + $space-half !default;

.google-translate-wrapper {
    margin: ($space-half + $space-sixth) auto;
    max-width: $googleTranslateDropdownMaxWidth;

    @include respond-min($mid-width) {
        margin: ($space-half + $space-sixth) 0;
    }

    &:first-child {
        margin-top: 0;
    }

    .site-header & {
        display: inline-block;
        margin: 0 $border-width-mid 0 0;
        vertical-align: middle;

        @include respond-min($small-width) {
            margin-right: $space-two-thirds;
        }
    }
}

.google-translate {
    background-color: $color-subtle;
    display: block;
    height: 100%;
    margin: 0;
    position: relative;

    .icon {
        left: $space-third;
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 0;

        .site-header & {
            @include respond-max($small-width) {
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.google-translate--loading {
        padding: $space-hard $space-third;

        .icon {
            position: static;
            transform: none;
        }
    }

    .goog-te-gadget {
        font-size: 0;
        position: relative;
        z-index: 1;

        .goog-te-combo {
            margin: 0;
        }
    }

    .goog-te-gadget-simple {
        background: transparent;
        border: 0;
        display: block;
        font-size: $normalis;

        .goog-te-menu-value {
            @include linkUnderline('span:first-child', $color-text-default, darken($color-subtle, 3%));
        }
    }

    .goog-te-menu-value {
        @include linkUnderlineReset();

        display: block;
        margin: 0;
        padding-left: remToEm($icon-size--inline) + $icon-inline-margin;

        img + span {
            @include visuallyhidden;
        }
    }

    .goog-te-combo {
        @extend %form__select;

        background-color: transparent;
        background-position: right $space-third center;
        background-size: $space-two-thirds;
        border: 0;
        display: block;
        font-size: $normalis-px; // select boxes must be minimum 16px to avoid iOS zoom bug
        margin: 0;
        padding: $space-third $space-and-third $space-third ($icon-size--inline + $space-two-thirds);
        text-align: left;

        @include respond-min($full-width) {
            font-size: $milli;
        }

        option {
            padding: 0;
        }

        .site-header & {
            padding: $space-hard $space-and-third $space-hard ($icon-size--inline + $space-two-thirds);

            @include respond-max($x-large-width) {
                background-image: none;
                max-width: $googleTranslateDropdownMaxWidthSmall;
                min-height: $space;
                padding: $space-hard 0 $space-hard ($icon-size--inline + $space-two-thirds);
            }

            @include respond-max($small-width) {
                padding: $space-and-sixth / 2 ($space-two-thirds + ($icon-size--inline / 2));
            }

            @include respond-max($x-small-width) {
                padding: $space-and-sixth / 2 ($space-half + ($icon-size--inline / 2));
            }
        }

        &::-ms-value {
            background-color: transparent; // remove blue focus background in IE
        }

        &:hover,
        &:focus {
            &::-ms-value {
                color: $color-text-default;
            }

            // remove ugly outline if Firefox
            &:-moz-focusring {
                color: transparent;
                text-shadow: 0 0 0 $color-black;
            }
        }
    }
}

.goog-te-banner-frame {
    // scss-lint:disable ImportantRule
    top: -$translateBannerIframeHeight !important;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top: 0 !important;
    }
    // scss-lint:enable ImportantRule
}

.goog-te-gadget-icon {
    display: none;
}
