// Links
//
// Base link styling
// See sass/objects/_link.scss.
//
// :hover       - Hover state.
// :active      - Active state.
// :focus       - Focus state.
//
// Markup:
// <a class="{$modifiers}">Link</a>
//
// Styleguide 11

%link-style {
    color: $color-link;
    font-weight: $font-weight-normal;
    text-decoration: none;

    &:visited {
        color: $color-link-visited;
    }

    &:hover {
        color: $color-link-hover;
        text-decoration: underline;
    }

    &:active,
    &:focus {
        outline: $border-width-large solid $color-link-focus;
    }
}

%link-style-a11y {
    color: $color-link;
    font-weight: $font-weight-bold;
    text-decoration: underline;

    &:visited {
        color: $color-link-visited;
    }

    &:hover {
        color: $color-link-hover;
        text-decoration: none;
    }

    &:active,
    &:focus {
        outline: $border-width-large solid $color-link-focus;
    }
}

a {
    @extend %link-style-a11y;
}

.link-external {
    @include textWordbreak();

    display: inline-block;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    &::after {
        background-size: cover;
        content: '';
        display: inline-block;
        height: $space-half;
        margin-left: $space-quarter;
        width: $space-half;
    }
}
