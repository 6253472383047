// ========================================================
//  Forms
// ========================================================
//  Styling for all forms within the CMS / Xforms,
//  XFP & FormBuilder [XFP2] forms.
// ========================================================

//  Base styling
// ==============

$select-height: 43px !default;

.form,
%form {
    @extend %cf;

    margin: $space-and-half 0 $space-double;

    .callout & {
        margin-top: 0;
    }

    fieldset {
        border: 0;
        padding-top: 0;
    }

    legend {
        white-space: normal;
    }

    input:not[type='checkbox'],
    input:not[type='radio'] {
        appearance: none;
        border-radius: 0;
    }

    input[type='search'] {
        appearance: none;
        box-sizing: border-box;
    }
}

// ========================================================
//  Form Structure
// ================
//  Top level structure, introduction, headings & actions
.form__heading {
    @extend %boxed__heading;

    margin-bottom: $space-three-quarters;

    .callout & {
        margin-top: 0;
    }
}

.form__instructions {
    margin-bottom: $slug;

    .one-column & {
        > .editor {
            max-width: 100%;
        }
    }
}

.form__actions {
    .button {
        margin-bottom: 0;

        @include respond-max($small-width) {
            width: 100%;
        }
    }
}

.form__action {
    @extend %cf;

    display: block;
    margin-bottom: $space;
    width: 100%;

    @include respond-min($small-width) {
        display: inline-block;
        margin-bottom: $space-half;
        margin-right: $space;
        vertical-align: top;
        width: auto;
    }
}

.form__action--secondary {
    float: left;
}

.form__footer {
    @extend %cf;

    border-top: $border-width-small solid $color-border;
    clear: both;
    padding-top: $slug;
}

// ========================================================
//  Form Rows
// ===========
//  Layout for each field block
.form__control {
    @extend %cf;

    margin: $space-half 0 $space;
    position: relative;

    &:first-of-type {
        margin-top: 0;

        .form__label {
            margin-top: 0;
        }
    }

    .site-main & {
        .dob {
            display: inline-block;
            width: percentage(3 / 10);

            @include respond-min($x-small-width) {
                width: percentage(3 / 4);
            }

            @include respond-min($mid-width) {
                width: percentage(1 / 5);
            }

            &--year {
                margin-right: 0;
            }

            .form__label {
                float: none;
                margin-right: 0;
                text-align: left;
                width: auto;
            }

            .form__field {
                float: none;
            }
        }

        .form__field + .button,
        .xfields + a + script + .button {
            margin-top: $space-third;
        }

        %form__field + .button[value="Lookup"],
        .form__select + .button[value="Lookup"] {
            height: $select-height;
        }

        %form__field + .date-picker-control {
            top: $space;
            transform: translateY(-50%);
        }

        .form__field + .button[value="Calculate"] {
        }

        .data-table {
            margin-bottom: 0;
        }

        .form__append-date-picker {
            display: block;

            %form__field {
                border-right: $border-width-small solid $color-grey-light;
            }
        }

        .date-picker-control {
            position: absolute;
            right: $space-half;
            top: $space-three-quarters + $space-twelfth;

            @include respond-min($large-width) {
                top: $space-half;
            }
        }

        .form__append-group {
            align-content: stretch;
            display: flex;
            justify-content: space-around;
            margin-top: $space-half;

            .form__field {
                flex: 0 1 auto;
                margin: 0;
            }

            .button {
                flex-shrink: 0;
                margin-left: $space-third;
                margin-top: 0;

                @include respond-min($mobile-break) {
                    margin-left: $space-two-thirds;
                }
            }

            .button--small {
                font-size: $epsilon;
                padding: $space-two-thirds $space-and-fifth $space-half;
            }

            .button--icon {
                padding: $space-eighth;
            }
        }

        td {
            .form__append-group {
                position: relative;

                .form__field,
                .xfields {
                    + .date-picker-control {
                        right: $space-third;
                        top: $space-two-thirds;
                    }
                }

                .form__field[name^="date"],
                .xfields[name^="date"] {
                    padding-right: $space-and-half;
                }
            }
        }
    }
}

.site-main {
    .form__control--error {
        border: $border-width-xx-large solid $color-danger-border;
        padding: $space-half;
    }

    .form__control--full {
        .form--block & {
            .form__label {
                margin-bottom: $space-third;
                text-align: left;
                width: 100%;
            }

            .form__control-group {
                width: 100%;
            }
        }
    }
}

.form__component {
    @extend %cf;

    clear: both;
    float: left;
    width: 100%;
}

// ========================================================
//  Form question blocks
// ======================

.form__fieldset {
    min-width: 0;

    .form__component {
        margin-top: $slug-small;
    }

    > .form__label {
        clear: both;
        float: left;
        font-weight: $font-weight-normal;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .form__label--legend {
        font-weight: $font-weight-bold;
        margin-bottom: 0;
    }

    .form__control {
        margin: $slug-small 0;

        .form__label {
            font-weight: $font-weight-normal;
            margin-bottom: 0;
        }
    }
}

.form__control-group {
    display: flex;

    .form__label {
        flex: 1 1 auto;
        font-weight: $font-weight-normal;
        order: 2;
    }

    .form__label--checkbox,
    .form__label--radio {
        flex: 0 0 auto;
        max-width: 100%;
        order: 1;
    }

    p {
        margin: $space-half 0 0;
    }
}

// ========================================================
//  Form Fields
// =============

.form__field {
    @extend %form__field;
}

.form__field--disabled {
    color: $color-text-disabled;
    font-style: italic;
}

// ========================================================
//  Form notes & error messaging
// ====================================

.form__control--error {
    .form__label {
        color: $color-danger-text;
    }

    .form__label--checkbox,
    .form__label--radio {
        color: inherit;

        &:hover,
        &:focus,
        &:active {
            color: $color-link;
        }
    }
}

.form__error-message {
    color: $color-danger-text;
}

.form--block .form__label--confirmation {
    float: none;
    padding-right: 0;
    width: 100%;
}

.form--block .form__field--confirmation {
    margin-top: $slug-small;
}
