.button--large,
%button--large {
    @include button-slide($color-primary, $color-button-primary);

    &,
    &:visited {
        @extend %epsilon;

        padding: $space $space-double;
    }
}
