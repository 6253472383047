.button--icon-left,
%button--icon-left {
    padding-left: $space-triple;

    &::before {
        background-position: center;
        background-size: 100%;
        content: '';
        height: $space-two-thirds;
        left: $space-and-two-thirds;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: $space-two-thirds;
        z-index: 2;
    }

    .icon {
        display: inline-block;
        height: $button-arrow-height;
        margin-right: $space-quarter;
        vertical-align: middle;
        width: $button-arrow-width;
    }
}
