$link-cta-width: $space-two-thirds;
$link-cta-height: $link-cta-width;
$link-cta-icon-size: percentage(2 / 3);

.link-cta,
%link-cta {
    @extend %cf;

    display: block;
    font-weight: $font-weight-bold;
    margin-top: $space-half;
    overflow: visible;
    position: relative;

    &:hover {
        text-decoration: none;
    }

    .icon {
        background-color: $color-primary-light;
        background-size: $link-cta-icon-size;
        border-radius: 50%;
        height: $space - $space-sixth;
        position: absolute;
        top: .25em;
        width: $space - $space-sixth;

        @include respond-min($mobile-break) {
            height: $link-cta-height;
            width: $link-cta-width;
        }
    }

    .link-cta__link,
    %link-cta__link {
        color: $color-primary-light;
        display: inline-block;
        padding-left: $space-and-sixth;
        vertical-align: middle;
    }
}

.link-cta--prev,
%link-cta--prev {
    @extend %link-cta;
}

.link-cta--next,
%link-cta--next {
    @extend %link-cta;

    .link-cta__link,
    %link-cta__link {
        padding-right: $space-and-sixth;
    }

    .icon {
        right: 0;
    }
}
