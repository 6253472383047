// ========================================================
// Progress bars
// ========================================================

// Options
$progressbarShowArrow: true !default;
//  if this option is set to true,
//  it recommended that the arrow and bar are the same size
//  eg: `$progressbarArrowSize: $progressbarHeight;`

$progressbarShowLeftNotch: false !default;
$progressbarBorderRadius: $space-eighth !default;

//  Colours
// ========================================================
$progressbarColor: $color-primary !default;
$progressbarBackgroundColor: $color-grey-lighter !default;

//  Size
// ========================================================
$progressbarHeight: $space-half !default;
$progressbarArrowSize: ($progressbarHeight + $space-sixth) !default;

// ========================================================

// ========================================================

.progress-bar {
    display: block;
    margin: $space-quarter 0 $space-three-quarters;
    padding: 0;
    position: relative;
}

.progress-bar__track {
    background: $progressbarBackgroundColor;
    border-radius: $progressbarBorderRadius;
    display: block;
    margin: $space-quarter 0 $space-three-quarters;
    padding: 0;
    position: relative;

    @if($progressbarShowArrow) {
        padding-right: $progressbarArrowSize; // add padding to the background accommodate the arrow

        // background notch on right
        &::after {
            border: $progressbarHeight solid $color-white;
            border-left-color: $progressbarBackgroundColor;
            border-right-width: 0;
            bottom: 0;
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            z-index: 0;
        }
    }

    @if($progressbarShowLeftNotch) {
        // background notch on left
        &::before {
            border: $progressbarHeight solid transparent;
            border-left-color: $color-white;
            border-right-width: 0;
            content: '';
            left: 0;
            position: absolute;
            top: 0;
            z-index: 2;
        }
    }
}

.progress-bar__indicator {
    background: $progressbarColor;
    padding: $progressbarHeight 0 $progressbarHeight $space-half;
    position: relative;
    z-index: 1;

    @if($progressbarShowArrow) {
        // left arrow
        &::before {
            border: $progressbarArrowSize solid transparent;
            border-left-color: $progressbarColor;
            border-right-width: 0;
            content: '';
            left: 100%;
            position: absolute;
            top: $progressbarHeight - $progressbarArrowSize;
        }
    }
}

.progress-bar__info {
    @extend %milli;

    color: $color-off-black;
    display: block;
    font-weight: $font-weight-normal;
    margin: 0 0 $space;

    strong {
        font-weight: $font-weight-normal;
    }
}
