.zoom-overlay {
    z-index: zIndex('modal-background');

    .zoom-overlay-open & {
        opacity: .5;
    }

    .zoom-overlay-transitioning & {
        opacity: 0;
    }
}

.zoom-img-wrap {
    transition: transform $transition-speed;
    z-index: zIndex('modal');
}

.zoom-overlay-open {
    .site-main {
        z-index: auto;
    }
}
