// Boxed
//
$boxed__border-radius: 5px;
$boxed__box-shadow: 1px 2px 5px darken($color-border, 1%);

.boxed,
%boxed {
    @extend %cf;
    @extend %module;

    background-color: $color-white;
    border-radius: $boxed__border-radius;
    box-shadow: $boxed__box-shadow;
    margin-bottom: 0;
    margin-top: $leading;
    padding: $leading;
    position: relative;

    > *:first-child {
        margin-top: 0;

        .nav__heading,
        .listing__heading,
        .form__heading {
            margin-top: 0;
        }
    }

    + .section-heading {
        margin: $leading 0 0;
    }
}

.boxed__form,
%boxed__form {
    background: none;
    border: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;
}

.boxed__heading,
%boxed__heading {
    @extend %epsilon;

    font-weight: $font-weight-bold;
    margin-top: 0;
}

.boxed__heading--space {
    margin-bottom: $space-half;
}

.boxed__subheading,
%boxed__subheading {
    @extend %zeta;
}

.boxed__copy,
%boxed__copy {
    margin-top: $space-third;
}

$separator--overhang: $leading - $space-quarter;

.boxed--separator::before {
    @include respond-min($mid-width) {
        background: $color-border;
        bottom: $separator--overhang;
        content: '';
        display: block;
        left: 50%;
        position: absolute;
        top: $separator--overhang;
        transform: translate(-50%);
        width: $border-width-mid;
    }
}

.boxed--flush,
%boxed--flush {
    margin-bottom: 0;
    margin-top: 0;
}

// For widgets that are not full-width...
// ...reduce the padding
.widget-width:not(.widget-width--full) {
    .boxed,
    %boxed {
        padding: $leading-small;
    }
}
