$in-my-area-icon-height: $space-quadruple + $space-two-thirds;
$in-my-area-icon-width: $in-my-area-icon-height;

.in-my-area {
    overflow: hidden;
    position: relative;

    @include respond-min($desk-break) {
        display: flex;
    }

    .container {
        width: 100%;
    }

    .widget__heading {
        font-weight: $font-weight-bold;
        margin-bottom: 0;
    }

    .form__control,
    .form__append-group {
        margin-top: 0;
    }

    .form__append-group {
        @include respond-max($desk-break) {
            display: block;
        }
    }

    .icon-in-my-area {
        @extend %icon--large;

        position: absolute;
        right: $space-quarter;
        top: $space-quarter;


        @include respond-min($desk-break) {
            margin-top: $space-third;
            min-height: $in-my-area-icon-height;
            min-width: $in-my-area-icon-width;
            position: static;
            right: auto;
            top: auto;
        }
    }

    $background-img-offset: ($space-triple + $space-third);
    $background-img-size: calc(100% - #{($background-img-offset * 2)});
    // 100% minus the width of the left hand icon.
    // Allows for correct positioning of background dotted line

    .icon-area-dottedline {
        display: none;

        @include respond-min($desk-break) {
            background-position: bottom;
            background-size: $background-img-size;
            bottom: $space-and-third;
            display: block;
            left: $background-img-offset;
            position: absolute;
            width: $background-img-size;
        }
    }

    .form {
        margin-top: $space-and-sixth;

        @include respond-min($desk-break) {
            flex: 1 0 auto;
            margin-top: 0;
        }
    }

    .form__label {
        font-size: $zeta;
        font-weight: $font-weight-normal;
        margin-top: $space-half;
        width: auto;

        @include respond-min($desk-break) {
            flex: 0 0 auto;
            padding: 0 $space-half;

            &:first-of-type {
                padding: 0 $space-half 0 0;
            }
        }
    }

    .form__field {
        color: $color-text-primary;
        margin: $space-quarter 0;

        @include respond-min($desk-break) {
            flex: 1 1 auto;
            float: none;
            margin: 0 0 0 $space-two-thirds;
        }

        @include respond-min($desk-break) {
            margin: 0 0 0 $space-two-thirds;
        }
    }

    .form__control .form__select {
        margin: $space-quarter 0;

        @include respond-min($desk-break) {
            flex: 1 4 40%;
            margin: 0;
        }
    }

    .form__control .form__append-group .form__field {
        margin: $space-quarter 0;
        min-width: $border-width-small; // Firefox bug

        @include respond-min($desk-break) {
            margin: 0;
        }
    }

    .form__control .form__append-group .button {
        margin: $space-quarter 0 0;
        width: 100%;

        @include respond-min($desk-break) {
            margin: 0 0 0 $space-two-thirds;
            padding: 0 $space-and-third 0 $space-and-half;
            width: auto;
        }

        .icon {
            height: $space;
            margin-left: 0;
            width: $space;

            @include respond-min($desk-break) {
                margin-left: $space-quarter;
            }
        }
    }
}

.in-my-area__content {
    @include respond-max($desk-break) {
        padding-top: $space;
    }

    @include respond-min($desk-break) {
        flex: 1 0 auto;
        max-width: calc(100% - #{$in-my-area-icon-width});
        padding-left: $space-and-third;
    }
}

.in-my-area__button-group {
    @include respond-min($desk-break) {
        display: flex;
        flex: 2 3 60%;
        margin-bottom: $space-double;
    }
}

// Results page

// scss-lint:disable SelectorFormat
.maplayerselect {
    @extend %cf;

    form {
        margin: 0;
    }
}

.mapLayerSelection {
    display: block;
    margin: $leading-small 0;

    @include respond-min($mid-width) {
        float: left;
        padding-left: $gutter;
        padding-right: $gutter;
        width: 50%;
    }
}
// scss-lint:enable SelectorFormat
