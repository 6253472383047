.button--standout,
%button--standout {
    @include button-slide($color-grey-light, $color-button-standout, $color-off-black);

    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        border-color: $color-off-black;
    }
}
