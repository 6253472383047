// Header
//
// This file is intentially sparse and are intended as placeholder for your header styles
// See sass/layout/_header.scss.
//
// Styleguide 18.2

$button-menu: lighten($color-secondary-light, 5%);
$button-menu-hover: $color-secondary-light;

.site-header__container {
    background-color: $color-primary;
    color: $color-primary-offset;
    margin-top: 0;
    padding: $space-third 0;
    text-align: center;

    @include respond-min($small-width) {
        text-align: left;
    }

    a {
        &,
        &:visited {
            color: $color-primary-offset;
        }
    }
}

.site-header__links {
    margin-bottom: $space;
    text-align: center;
    width: 100%;

    @include respond-min($small-width) {
        float: right;
        padding-left: $space-half;
        text-align: right;
        width: percentage(2 / 3);
    }

    .list__item {
        margin-bottom: 0;
        margin-right: 0;

        &:not(:first-child) {
            @include respond-min($x-small-width) {
                margin-left: $space;
            }
        }
    }
}

.site-header__navigation {
    float: right;
    padding-left: $space-half;
    text-align: right;

    @include respond-min($mobile-break) {
        width: 50%;
    }

    @include respond-min($tablet-break) {
        width: percentage(2 / 3);
    }

    .list {
        margin-bottom: $space-third;
    }

    .list__item {
        margin-right: 0;
        vertical-align: baseline;

        .js & {
            margin-right: $space-third;

            @include respond-min($tablet-break) {
                margin-right: $space;

                &:first-child {
                    margin-right: $space-third;
                }
            }
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .icon--left {
        float: left;
        margin: -$space-twelfth 0 0;

        @include respond-min($tablet-break) {
            margin-right: $space-third;
        }
    }

    .list__link {
        display: block;

        &,
        &:visited,
        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
            text-decoration: none;
        }
    }

    .list__link-title {
        display: inline-block;
    }
}

.site-header__menu-button {
    @extend %button--icon-right;
    @include button-slide($button-menu, $button-menu-hover);

    border-width: 0;
    display: none;
    padding: $space-third $space-half;

    @include respond-min($mobile-break) {
        padding: $space-half $space-two-thirds;
    }

    @include respond-min($tablet-break) {
        border-width: $button-border-width; // Resetting border width
        padding-left: $space-three-quarters;
    }

    .js & {
        display: inline-block;
        font-weight: $font-weight-bold;
    }

    .icon {
        width: $space;

        @include respond-min($tablet-break) {
            margin-left: $space-half;
        }
    }
}

.site-header__button {
    &.button {
        border-color: $color-white;
        font-weight: $font-weight-bold;
        padding: $space-third;

        @include respond-min($mobile-break) {
            padding: $space-half;
        }

        @include respond-min($tablet-break) {
            border-color: transparent;

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;

                .site-header__button-text {
                    text-decoration: underline;
                }
            }

            &::after {
                content: none;
            }
        }
    }
}

.site-header__button--bookmarks {
    display: none;

    .js & {
        display: inline-block;
    }

    &[aria-expanded="true"] {
        &,
        &:focus,
        &:active {
            background-color: $color-teal-dark;
        }
    }
}

.site-header__button-text {
    display: none;

    @include respond-min($tablet-break) {
        display: inline;
    }
}

.site-header__top-links {
    margin: -$space-quarter 0 $space-quarter;
}

.site-search {
    @extend %form-search;

    background-color: $color-primary-light;
    display: block;
    margin: 0;
    padding: $space-half 0;

    .form__control .form__append-group .button {
        margin: 0;
    }
}
