.alert--information,
%alert--information {
    border-color: $background--information;

    @include respond-max($small-width) {
        background-color: $background--information;
    }
}

.alert--info {
    @extend %alert--information;
}
