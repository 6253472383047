.data-table--striped,
%data-table--striped {
    tbody {
        tr {
            &:nth-child(odd) {
                background-color: $color-grey-light;
            }
        }
    }
}
