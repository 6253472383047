.list--definition {
    @extend %cf;
    margin-top: $space;
}

.definition__heading {
    border-top: $border-width-mid solid $color-border;
    clear: left;
    font-weight: bold;
    padding-top: $space-two-thirds;

    @include respond-min($mid-width) {
        float: left;
        padding: $space-two-thirds $space-half;
        text-align: right;
        width: percentage(1 / 4);
    }
}

.definition__content {
    margin: 0;
    padding-bottom: $space-two-thirds;
    padding-top: $space-sixth;

    @include respond-min($mid-width) {
        border-top: $border-width-mid solid $color-border;
        float: left;
        padding: $space-two-thirds 0 $space-two-thirds $space-and-half;
        width: percentage(3 / 4);
    }
}

.definition__map,
.definition__image {
    display: block;
    margin: $space-sixth 0 $space-fifth;
}

.definition__editor {
    @extend %module;

    > *:first-child {
        margin-top: 0;
    }
}
