$listing-image-size: pxToRem(200px) !default;


%listing,
.listing {
    .listing__link {
        color: $color-text-default;
        display: flex;
        flex-direction: column;
        text-decoration: none;

        @include respond-min($small-mid-width) {
            flex-direction: row;
        }

        &:hover,
        &:focus,
        &:active {
            .listing__heading {
                color: $color-link-hover;
            }
        }
    }

    .listing__content {
        flex: 1 1 auto;
    }

    .listing__heading {
        color: $color-link;
        display: inline;
        font-size: $delta;
        margin: 0 0 $slug-small;
    }

    .listing__summary {
        font-weight: $font-weight-normal;
        margin-bottom: 0;
        margin-top: $slug-smaller;
    }

    .listing__meta {
        margin-top: $space-half;
    }

    .listing__image {
        align-self: flex-start;
        margin: $slug 0 0;
        max-width: 100%;

        @include respond-min($small-mid-width) {
            flex: 0 1 auto;
            margin: 0 0 0 $gutter-large;
            width: $listing-image-size;
        }
    }
}
