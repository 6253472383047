// Latest news widget
.widget-latest-news {
    @extend %boxed;
    @extend %boxed--border-primary;
    @extend %boxed--flush;

    .button {
        margin-top: $space;
    }
}
