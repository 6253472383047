// ========================================================
//  Site Footer
// ==============

$icon-footer-width: $space-quadruple * 2 !default;
$icon-footer-height: $space-and-third + $space-double !default;

$icon-sign-up-size: $space-double;

.site-footer {
    background-color: $color-primary;
    color: $color-off-white;
    margin: 0;

    @include respond-min($small-width) {
        font-size: $milli;
        text-align: left;
    }

    a,
    a:visited {
        color: $color-off-white;
    }

    a:hover,
    a:active,
    a:focus {
        color: $color-white;
    }
}

.site-footer__top {
    background-color: $color-primary-light;
    overflow: hidden;
    padding: $space 0;
    text-align: center;
}

.sign-up {
    @extend %link-style;

    display: inline-block;
    justify-content: center;
    margin: $space-half auto 0;
    position: relative;
    text-align: left;

    @include respond-min($mobile-break) {
        display: flex;
        float: left;
        justify-content: space-between;
        width: percentage(1 / 3);
    }

    @include respond-min($desk-break) {
        width: percentage(1 / 4);
    }
}

.sign-up__icon {
    height: $space-double;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $space-double;

    @include respond-min($mobile-break) {
        flex-grow: 0;
        flex-shrink: 0;
    }

    svg {
        height: $space-double;
        width: $space-double;
    }

    .no-js & {
        background-position: center;
        background-size: contain;
        height: $icon-sign-up-size;
        width: $icon-sign-up-size;
    }
}

.sign-up__text {
    display: block;
    margin-bottom: $space-quarter;
    padding-left: $space-double + $space-third;


    @include respond-min($mobile-break) {
        flex-grow: 1;
        flex-shrink: 1;
        margin: $space-twelfth 0 $space-quarter $space-half;
        text-align: left;
    }
}

.site-footer__social-links {
    @include respond-min($mobile-break) {
        float: right;
        margin-top: $space-half;
        text-align: right;

        .list__item {
            display: inline-block;
        }
    }

    .list {
        text-align: center;
    }
}

@include respond-max($mobile-break) {
    // Flip order of "Sign Up" and "Social Media"
    .site-footer__top {
        transform: rotate(180deg);
    }

    .sign-up {
        margin: 0 auto $space-half;
        transform: rotate(180deg);
    }

    .site-footer__social-links {
        transform: rotate(180deg);
    }
}

.site-footer__content {
    padding: $space-and-half 0;
}

.site-footer__links {
    width: 100%;

    @include respond-min($mobile-break) {
        float: left;
        max-width: 50%;
    }
}

.site-footer__information {
    margin-top: $space-and-half;
    text-align: left;
    width: 100%;

    @include respond-min($mobile-break) {
        float: right;
        margin-top: 0;
        text-align: right;
        width: 50%;
    }
}

.site-footer__logo {
    display: inline-block;

    .icon {
        height: $icon-footer-height;
        width: $icon-footer-width;
    }
}

.site-footer__logo-link {
    display: block;
    opacity: 1;
    transition: $transition-speed opacity;

    &:hover {
        opacity: .7;
    }
}

.list--site-footer {
    margin: 0;

    @include respond-min-and-max($x-small-width, $mobile-break) {
        column-count: 2;
    }

    @include respond-min($tablet-break) {
        column-count: 2;
    }

    .list__item {
        display: block;
        margin: 0 $space-and-third $space-twelfth 0;
    }

    .list__link {
        display: block;
        font-weight: $font-weight-bold;

        &:focus {
            outline: $border-width-mid solid $color-link-focus;
            outline-offset: -$border-width-mid;
        }
    }
}

.site-footer__address,
.site-footer__rights,
.suppliers__content {
    margin: $space-quarter 0;
}

.site-footer__rights {
    margin: 0;
}

.site-footer__social-links {
    @include respond-min($small-width) {
        float: right;
    }
}

.list--social-links {
    margin: 0;

    .list__item {
        display: inline-block;
        margin: 0 $space-two-thirds $space-two-thirds 0;

        @include respond-min($small-width) {
            margin: 0 0 $space-third $space-two-thirds;
        }
    }

    .list__link {
        display: block;
        height: $space-and-two-thirds;
        opacity: .935;
        overflow: hidden;
        transition: $transition-speed;
        width: $space-and-two-thirds;

        &,
        &:visited {
            text-decoration: none;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
            opacity: 1;
            transform: scale(1.1);
        }
    }
}
