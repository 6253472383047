// Events calendar widget
.widget-calendar {
    @extend %boxed;
    @extend %boxed--border-primary;
    @extend %boxed--flush;

    .widget__heading {
        text-align: center;
    }
}
