// Base styling for carousel

$carousel-slide-width:  70% !default;
$carousel-slide-width: 100%;

$carousel-color-background: $color-primary !default;
$carousel-color-text:       $color-primary-offset !default;

$carousel-button-color-background: $color-primary-offset !default;
$carousel-button-color-icon:       $color-primary !default;

$carousel-button-size: 4rem !default;

$slick-arrow-color:         $color-white;
$slick-dot-color:           $color-secondary;
$slick-dot-color-active:    $color-primary;
$slick-dot-color-hovered:   $color-primary-light;
$slick-dot-size:            6px !default;


// Basic layout
.carousel {
    display: block;
    margin-top: $slug;
    position: relative;

    &:first-child {
        margin-top: 0;
    }
}

.carousel--one-slide {
    @extend .boxed;

    padding: 0;

    .carousel__slide {
        margin-bottom: 0;
        margin-top: 0;
        padding: $space-and-half $space-and-half 0;
    }

    .carousel__content {
        padding: $space-half 0 0;
    }

    .carousel__heading {
        margin-top: 0;
    }

    .flickity-page-dots {
        margin: 0;
        padding-bottom: $space-and-half;
        padding-top: $space-half;
    }
}

.slide {
    padding: 0;
    position: relative;
    width: $carousel-slide-width;

    &[aria-hidden="true"] {
        display: block;
    }
}

.slide__media,
.slide__content,
.slide__video {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
}

.slide__video {
    // guess at 16:9 ratio
    padding-top: 56.25%;
    position: relative;

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}



// colour and themeing
.slide__content {
    padding: $gutter (($carousel-button-size + pxToRem($border-width-mid)) - $space);
}

.slide__heading {
    color: $carousel-color-text;
    margin: 0;
}

.slide__summary {
    margin: $space-half 0 0;
}

.slide__link {
    margin: $space 0 0;
}

// Animation and states
.slide {
    .js & {
        opacity: .5;
        transition: opacity $transition-speed-slow;
    }

    &.is-selected {
        opacity: 1;
    }
}

// flickity overrides
.carousel {
    .flickity-page-dots {
        bottom: auto;
        display: block;
        padding: 0 0 $space-half;
        position: relative;

        .dot {
            background: $color-white;
            border: $border-width-mid solid $slick-dot-color;
            margin: 0 $space-sixth;
            opacity: 1;

            &.is-selected {
                background: $slick-dot-color;
            }

            &:hover,
            &:focus {
                outline: $border-width-small solid $color-link-focus;
            }
        }
    }

    .flickity-prev-next-button {
        background-color: $carousel-button-color-background;
        border: $border-width-mid solid $carousel-button-color-icon;
        border-radius: 0;
        box-sizing: border-box;
        color: $carousel-button-color-icon;
        height: $carousel-button-size;
        opacity: .9;
        top: 50%;
        transition: $transition-speed-slow $bezier;
        width: $carousel-button-size;

        &:hover {
            background-color: lighten($carousel-button-color-icon, 10%);
            border-color: $carousel-button-color-icon;
            color: $carousel-button-color-background;
            opacity: 1;

            /* icon color */
            .flickity-button-icon {
                fill: $carousel-button-color-background;
            }
        }

        &:focus {
            // box shadow is used so the focus ring can be spherical.
            box-shadow: 0 0 0 $border-width-large $color-link-focus;
        }

        &.next {
            right: -$space-fifth;

            @include respond-min($x-large-width) {
                right: -$space-half;
            }
        }

        &.previous {
            left: -$space-fifth;

            @include respond-min($x-large-width) {
                left: -$space-half;
            }
        }
    }

    /* icon color */
    .flickity-button-icon {
        fill: $carousel-button-color-icon;
    }

    .flickity-slider {
        &.has-focus {
            // scss-lint:disable ImportantRule
            transform: translateX(0) !important; // !important needed to override Flickity JS

            .slide {
                left: 0 !important; // !important needed to override Flickity JS
                opacity: 0;

                &.is-selected {
                    opacity: 1;
                }
            }
            // scss-lint:enable ImportantRule
        }
    }
}

.flickity-slider {
    > * {
        &[aria-hidden="true"] {
            display: block;
        }
    }
}

// scss-lint:disable ImportantRule
.carousel--fade {
    .flickity-slider {
        left: 0 !important;
        transform: none !important;
    }

    .carousel__slide {
        left: 0 !important;
        opacity: 0;
        z-index: 0;

        &.is-selected {
            opacity: 1;
            z-index: 1;
        }
    }

    .flickity-prev-next-button {
        z-index: 2;
    }
}
// scss-lint:enable ImportantRule
