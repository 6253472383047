.esri-web-service {
    @extend %boxed;
    @extend %boxed--border-primary;

    .form__append-group {
        @include respond-max($desk-break) {
            flex-wrap: wrap;
        }
    }

    .form__control {
        .form__append-group {
            .button--icon-right-default {
                margin: $space-half 0 0;
                width: 100%;

                @include respond-min($desk-break) {
                    margin: 0 0 0 $space-two-thirds;
                    padding: 0 $space-and-third 0 $space-and-half;
                    width: auto;
                }

                .icon {
                    height: $icon-size--small;
                    margin-left: 0;
                    width: $icon-size--small;

                    @include respond-min($desk-break) {
                        margin-left: $space-quarter;
                    }
                }
            }
        }
    }
}
