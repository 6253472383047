// VARS / CONFIG
$download_meta-width:   15rem !default;
$download_break-point:  $small-width !default;
$download_border-width: $border-width-small !default;

.list--downloads {
    .list__item {
        margin: 0;
    }
}

.download {
    border-bottom: 0;
    border-top: $download_border-width solid $color-disabled;
    display: block;

    @include respond-max($download_break-point) {
        padding: $space-two-thirds 0 $space-third;
    }
}

.download__link {
    display: block;

    .download__heading {
        color: $color-link;
        text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
        .download__cta {
            background-color: $color-primary;
            color: $color-standout-offset;
            text-decoration: none;
        }

        .download__heading {
            color: $color-link-hover;
            text-decoration: none;
        }
    }
}

.download__heading {
    display: block;
    font-size: $zeta;
    margin: 0;
    padding: 0 0 $space-half;

    @include respond-min($download_break-point) {
        display: inline-block;
        font-size: $normalis;
        padding-right: $space-half;
        padding-top: $space-half;
        vertical-align: middle;
        width: calc(100% - #{$download_meta-width});
    }
}

.download__meta-list {
    @extend .list;
    @extend %cf;

    margin: 0;
    padding: 0;

    @include respond-min($download_break-point) {
        display: inline-block;
        text-align: right;
        vertical-align: top;
        width: $download_meta-width;
    }
}

.download__meta {
    color: $color-text-default;
    display: inline-block;
    font-weight: normal;
    margin: $space-half 0 $space-half $space-half;
    padding: 0 $space-sixth;
    vertical-align: top;

    @include respond-min($download_break-point) {
        font-size: $milli;
        margin-top: ($space-half / ($milli)) * 1rem;
    }
}

.download__meta--type {
    @include respond-min($download_break-point) {
        float: left;
        text-align: center;
        width: 3.5rem; // the width of "DOCX" with a bit of breathing space
    }
}

.download__meta--link {
    background-color: transparent;
    float: left;
    margin: 0;
    padding: 0;

    @include respond-min($download_break-point) {
        float: none;
        font-size: $normalis;
        margin-left: $space-half;
    }
}

.download__cta {
    background-color: $color-secondary;
    border-radius: $border-width-large $border-width-large 0 0;
    color: $color-standout-offset;
    display: block;
    margin: $download_border-width * -1 0 0;
    padding: $space-third $space-third $space-third $space-half;
    transition: background-color $transition-speed-mid;

    @include respond-min($mobile-break) {
        border-radius: 0;
        border-top: $download_border-width solid mix($color-standout, $color-black, 80%);
        padding: $space-third;
    }
}

.download__cta-text {
    color: $color-standout-offset;
    display: inline-block;
    padding: 0 $space 0 0;
    vertical-align: middle;

    @include respond-min($download_break-point) {
        display: none;
    }
}

.download__cta-icon {
    display: inline-block;
    vertical-align: middle;
}
