.data-table--bordered,
%data-table--bordered {
    border: $border-width-small solid $color-purple;
    margin-bottom: $space;

    caption {
        margin-bottom: $space-half;
    }

    thead {
        background-color: $color-subtle;
    }

    tbody {
        tr:first-child {
            td {
                padding-top: $space-half;
            }
        }
    }

    th {
        + th {
            border-left: $border-width-small solid $color-purple;
        }
    }

    td {
        border: $border-width-small solid $color-purple;
        padding-bottom: $space-half;
        padding-top: $space-half;
    }
}
