%image-link,
.image-link {
    border-collapse: collapse;
    display: table;
    margin: $slug 0;
    max-width: 100%;
    vertical-align: top;

    .image__caption {
        caption-side: bottom;
        color: $color-link;
        display: table-caption;
        flex: 0 1 0;
        margin: 0;
        padding: $space-half $gutter-small;
        transition: color $transition-speed;
    }

    .image--with-link {
        width: 100%;
    }

    &::before {
        border-top-color: $color-subtle;
        bottom: 0;
        z-index: 2;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;

        .image__caption {
            background-position: bottom left;
            color: $color-link-hover;
        }

        &::before {
            transform: scale(1, 1.5) translate(-$space-sixth, $border-width-mid * 2);
        }

        &::after {
            bottom: -$space-quarter;
            height: calc(100% + #{$space-half});
        }
    }
}
