.button--small,
%button--small {
    @include button-slide($color-primary, $color-button-primary);

    &,
    &:visited {
        @extend %micro;

        padding: $space-half;
    }
}
