%form-search {
    .form__control {
        margin: 0;
        position: relative;
    }

    .form__append-group {
        height: $space-and-third + ($normalis) * $line-height-ratio;
        margin: 0;
    }

    .form__label {
        left: $space-half;
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        z-index: 3;
    }

    .form__label-text {
        @extend %visually-hidden;
    }

    .site-search__form {
        background: transparent;
        border: 0;
        box-shadow: none;
        margin: 0 auto;
        max-width: $container-inner;
        padding: 0;
    }

    .form__field {
        border: 0;
        border-radius: $button-border-radius 0 0 $button-border-radius;
        flex: 1 1 auto;
        font-size: $epsilon;
        height: $space-and-third + ($normalis) * $line-height-ratio;
        margin: 0;
        padding: $space-quarter ($space-two-thirds + $space-and-third + ($normalis) * $line-height-ratio) $space-quarter ($space-and-quarter * 2);
        width: auto;
        z-index: 2;

        &:focus {
            outline: $border-width-large solid $color-link-focus;
            outline-offset: 0;

            ~ .button::before {
                display: none;
            }
        }
    }

    .button {
        border-radius: 0 $button-border-radius $button-border-radius 0;
        font-weight: $font-weight-bold;
        margin: 0;
        overflow: visible;
        position: relative;
        right: 0;
        top: 0;

        &:hover,
        &:focus {
            background-color: darken($color-grey-light, 5%);
            border-color: darken($color-grey-light, 5%);
            color: $color-primary;

            &::before {
                border-right-color: darken($color-grey-light, 5%);
            }
        }

        &:focus {
            z-index: 2;

            &::before {
                content: none;
            }
        }

        &::before {
            @include button-arrow(left);
        }

        .js &::after {
            content: none;
            left: -$border-width-large;
        }
    }
}
