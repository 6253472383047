.list--basket,
%list--basket {
    border-top: $border-width-small solid $color-border;
    margin: 0;
    padding: $space-third 0;

    &:first-of-type {
        border-top: 0;
    }

    .list__item {
        margin: 0;

        &.list__item--basket {
            margin: $space-third 0 $space-and-two-thirds;
            padding: 0;
        }
    }
}
