.list--gallery {
    .list__item {
        box-sizing: border-box;
        display: inline-block;
        padding: $space-half;
        text-align: center;
        vertical-align: top;
        width: percentage(1 / 4);
    }

    img {
        width: 100%;
    }
}
