.calendar {
    display: block;
    min-width: 100px;

    th,
    td {
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .button {
        display: inline-block;
        margin-bottom: $space-two-thirds;
        margin-top: -$space-third;
        min-width: $space-quadruple;
    }
}

.calendar__heading,
.calendar__month-name {
    text-align: center;
}

.calendar__table {
    width: 100%;
}

.calendar__days {
    background-color: $color-primary;
    color: $color-white;

    th {
        font-weight: $font-weight-bold;
        padding: 3.5% $space-sixth 4%;
    }

    .sa,
    .su {
        background-color: lighten($color-primary, 4%);
    }
}

.calendar__dates {
    background: $color-white;

    tr {
        border-bottom: $border-width-small solid $color-disabled;
    }
}

.calendar__date {
    color: lighten($color-text-default, 20%);
    display: block;
    height: 100%;
    padding: $space-half $space-quarter;
}

.calendar__date--today {
    background-color: $color-primary;
    color: $color-white;
}

.calendar__date--link {
    color: $color-link;
    font-weight: $font-weight-bold;

    transition: background $transition-speed-fast ease-in-out;

    &.calendar__date--today {
        color: $color-white;
    }

    &:hover,
    &:focus {
        background-color: mix($color-primary, $color-white, 15%);
        color: $color-primary;
    }

    &:visited {
        color: $color-pink;
    }

    &.calendar__date--today {
        &:hover,
        &:focus {
            background-color: $color-pink;
            color: $color-white;
        }
    }
}
