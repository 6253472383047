%form__label,
.form__label,
.form__legend {
    @extend %normalis;

    color: $color-text-default;
    display: block;
    font-size: $body-font-size;
    font-smoothing: antialiased;
    font-weight: $font-weight-bold;
    margin-top: $space-half;
    padding: 0;
    width: 100%;

    em {
        display: block;
        font-size: $normalis;
    }

    .form__required,
    .form__required-note {
        display: inline-block;
    }

    &--checkbox,
    &--radio {
        width: 100%;
    }
}
