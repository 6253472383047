// ======
//  Grid
// ======

//  General Styles
// ================
%grid,
.grid {
    @extend %cf;
    @extend %list;

    display: block;
    margin: 0 0 (-$slug-small) (-$gutter-small);

    .boxed {
        display: block;
    }
}

%grid__cell,
.grid__cell {
    display: inline-block;
    margin: 0;
    padding: 0 0 $slug-small $gutter-small;
    vertical-align: top;
    width: 100%;
}

.grid--flush {
    margin: 0;

    .grid__cell {
        padding: 0;
    }
}

.h-separator {
    border-top: $border-width-mid solid $color-border;
    margin: $leading 0;
}

//  Modifiers
// ===========

//  2 Up
%grid--2up,
.grid--2up {
    .grid__cell {
        @extend %grid__cell--2up;
    }
}

%grid__cell--2up {
    @include respond-min($small-width) {
        width: percentage(1 / 2);

        &:nth-child(2n+1) {
            clear: left;
        }
    }
}

//  3 Up
%grid--3up,
.grid--3up {
    .grid__cell {
        @include respond-min($small-width) {
            width: percentage(1 / 3);

            &:nth-child(3n+1) {
                clear: left;
            }
        }
    }
}

// 4 Up
.grid--4up {
    .grid__cell {
        @include respond-min-and-max($small-width, $mid-width) {
            width: percentage(1 / 2);

            &:nth-child(2n+1) {
                clear: left;
            }
        }

        @include respond-min-and-max($mid-width, $large-width) {
            width: percentage(1 / 3);

            &:nth-child(3n+1) {
                clear: left;
            }
        }

        @include respond-min($large-width) {
            width: percentage(1 / 4);

            &:nth-child(4n+1) {
                clear: left;
            }
        }
    }
}
