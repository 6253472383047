$button-width: 140px !default;

.site-search {
    @extend %form-search;

    background-color: $color-primary-light;
    display: block;
    margin: 0;
    padding: $space-half 0;

    .form__control {
        .form__append-group {
            .form__field {
                @include respond-max($small-mid-width) {
                    width: calc(100% - #{$button-width});
                }
            }

            .button {
                border-color: $color-grey-light;
                margin: 0;

                &:hover,
                &:active,
                &:focus {
                    border-color: darken($color-grey-light, 5%);
                }
            }
        }
    }
}
