$editor-list-start-limit: 22 !default; // <ol start="x"> for custom ordered list styling
$max-image-width: 20rem !default;

// Output of the Xinha document editor
.editor {
    max-width: $content-max-width;

    // scss-lint:disable IdSelector,SelectorFormat
    a:not(.button) {
        &,
        &:visited {
            text-decoration: underline;
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }

    .button {
        margin-bottom: $slug;
        width: 100%;

        @include respond-min($x-small-width) {
            width: auto;
        }
    }

    .text_align_left {
        text-align: left;
    }

    .text_align_right {
        text-align: right;
    }

    .text_align_center {
        text-align: center;
    }

    .indent {
        margin-left: 20px;
    }

    .indent2 {
        margin-left: 40px;
    }

    strong {
        font-weight: 700;
    }

    em {
        font-style: italic;
    }

    ul:not([class]):not([type]) {
        @extend %list--bullet;

        margin-bottom: $space;
        margin-left: 0;

        ul {
            li {
                @extend %list__item--child;
            }
        }
    }

    ol:not([class]):not([type]) {
        margin-bottom: $space;
        margin-left: $space-three-quarters;

        li {
            padding-left: $space-half;

            &::before {
                display: none;
            }
        }
    }

    li {
        @extend %list__item;
    }

    .button {
        @extend %button--icon-right;
        @extend %button--secondary;

        margin-bottom: $space;
    }

    @for $i from 2 through $editor-list-start-limit {
        ol:not([class]):not([type])[start="#{$i}"] {
            counter-reset: list_item_count #{$i - 1};
            margin-top: 0;
        }
    }

    .snippet,
    iframe {
        max-width: 100%;
    }

    .underline_text {
        text-decoration: underline;
    }

    .strikethrough_text {
        text-decoration: line-through;
    }

    .underline_and_strikethrough_text,
    .underline_text.strikethrough_text {
        text-decoration: line-through underline;
    }

    sup,
    .superscript_text {
        vertical-align: super;
    }

    sub,
    .subscript_text {
        vertical-align: sub;
    }

    .float_left {
        @include respond-min($small-width) {
            float: left;
            margin-bottom: $space-half;
            margin-right: $space;
            max-width: 50%;
        }
    }

    .float_right {
        @include respond-min($small-width) {
            float: right;
            margin-left: $space;
            margin-top: $space-half;
            max-width: 50%;
        }
    }

    img:not([class]) {
        display: inline;
        margin: $space-half 0 0;
        vertical-align: baseline;
    }

    p {
        img:not([class]) {
            &:first-child {
                margin-top: $space-third;
            }
        }
    }

    .image_centre {
        display: block;
        margin: $space auto;

        img:not([class]) {
            margin: 0 auto;
        }
    }

    td.highlightCell,
    tr.highlightRow td {
        background: #cfc;
    }

    tr.zebra td {
        background: #ffc;
    }

    abbr {
        border-bottom: $border-width-small dotted #ccc;
        cursor: help;
    }

    .placeholder {
        background: #eee;
        border: $border-width-small solid #ccc;
        color: #333;
        padding: 2em;
    }

    #editable {
        border: $border-width-mid dashed #999;
        padding: 10px;
    }

    #editable::after {
        clear: both;
        content: '.';
        display: block;
        height: 0;
        visibility: hidden;
    }

    #toolbar {
        background-color: #eee;
        border: $border-width-small solid #ddd;
        padding: .5em;
    }

    .imageCaption {
        @extend .image--caption;

        @include respond-max($mobile-break) {
            margin: $space auto;
            width: 100%;
        }

        @include respond-min($mobile-break) {
            max-width: $max-image-width;
        }

        @include respond-min($tablet-break) {
            margin-bottom: $space;
        }

        img {
            margin: 0 0 $space-quarter;
            padding: 0;
        }

        &.float_left {
            float: left;
            padding: 0;

            @include respond-min($mobile-break) {
                margin-right: $space;
            }
        }

        &.float_right {
            padding: 0;

            @include respond-min($mobile-break) {
                margin-left: $space;
            }
        }

        &.image_centre {
            border-collapse: collapse;
            clear: both;
            display: table;
            float: none;
            margin: $space auto;

            p {
                caption-side: bottom;
                display: table-caption;
            }
        }

        p {
            @extend %image__caption;
        }

        &:first-child,
        &.float_left:first-child,
        &.float_right:first-child {
            margin-top: 0;
        }
    }
    // scss-lint:enable IdSelector,SelectorFormat
}
