$mobile-overlay-offset: $space-quadruple;
$navigation-toggle-width: 4em;
$navigation-toggle-height: $navigation-toggle-width;
$overlay-width: 40vw;
// em used instead of rem to keep the size consistant, prevents toggle lines jumping on resize

.navigation {
    bottom: 0;
    color: $color-white;
    display: none;
    height: 100%;
    padding: $space-triple $space $space-double;
    position: absolute;
    right: 0;
    top: 0;
    transition: $transition-speed all $bezier;
    width: 100%;
    z-index: 5;

    @include respond-min($tablet-break) {
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        top: 0;
        width: $overlay-width;
    }

    &[aria-hidden='false'],
    &.is-open[aria-hidden='true'] {
        display: block;
        opacity: 0;
        transform: translateX(100%);
    }

    &.is-open {
        opacity: 1;
        transform: translateX(0);
    }

    .nav-toggle {
        margin-left: 0;
    }

    .list__link {
        color: $color-white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.navigation__button {
    border: $border-width-mid solid $color-white;
    height: $navigation-toggle-height;
    padding: ($space-third + $space-fifth) $space-two-thirds;
    position: absolute;
    right: $space-two-thirds;
    top: $space-two-thirds;
    width: $navigation-toggle-width;
}

.focus-loop {
    @extend %visually-hidden;
}

@import 'overlays/visited-pages';
@import 'overlays/menu';
