// Supplements
//
// Supplement styling, see /sass/objects/_supplements.scss for CMS related placeholder classes (WIP)
//
// Styleguide 16

$supplement-accent-color: 'primary-light';

.supplements {
    @extend %cf;
}

.supplements--secondary {
    margin-top: $space-double;
}

.supplements--tertiary {
    @extend %full-width-background;
}

.supplement {
    @extend %boxed;
    @extend %boxed--border-#{$supplement-accent-color};

    margin-bottom: 0;
    margin-top: $leading;

    .supplements--secondary & {
        // Inherit boxed layout, not appearance
        border-bottom: 0;
        border-radius: 0;
        border-top: $border-width-mid solid $color-border;
        box-shadow: none;
        margin: $space 0 $space-double;
        padding: $space-double 0 $space-half;
    }
}

.supplement__heading {
    @extend .boxed__heading;
}

.supplement__subheading {
    font-size: $zeta;
}

.supplement__text {
    margin: 0 0 $slug;

    strong {
        margin-right: $space-half;
    }
}

.supplement__cta {
    @extend %link-cta !optional;

    display: inline-block;

    .icon {
        background-color: $color-secondary;
        display: inline-block;
        transition: background-color $transition-speed-fast ease;
        vertical-align: bottom;
    }

    .supplement__cta-text {
        @extend %link-cta__link;

        color: $color-primary;
        display: inline-block;
        vertical-align: bottom;
    }

    &:hover {
        .icon {
            background-color: $color-primary;
        }

        .supplement__cta-text {
            text-decoration: underline;
        }
    }
}

// For supplements that have full width images
.supplement--flush {
    .aside--sidebar & {
        padding: $space $space-and-quarter;

        @include respond-min($mid-width) {
            padding: 0;
        }
    }

    .image {
        .aside--sidebar & {
            @include respond-min($mid-width) {
                width: 100%;
            }
        }
    }

    .supplement__content {
        .aside--sidebar & {
            @include respond-min($mid-width) {
                padding: $space $space-and-quarter;
            }
        }
    }
}
