.list__group {
    margin: 0 0 $leading-small;

    .list {
        border-top: $border-width-mid solid $color-border;
        margin-top: 0;
    }

    .list__item {
        display: inline-block;
        margin-right: $gutter-small;
        margin-top: $space-third;
        padding: 0 0 $space-third;

        &:nth-of-type(n) {
            border: 0;
        }
    }

    .list__link {
        background: mix($color-purple, $color-white, 5%);
        padding: $space-sixth $space-third;
    }
}

.list__group-heading {
    @extend %boxed__subheading;
    margin-bottom: 0;
    padding-bottom: $space-sixth;

    a {
        @extend %link-style;

        font-weight: inherit;
    }
}
