// Image widget
.widget--images {
    @extend %boxed;
    @extend %boxed--border-primary;
    @extend %boxed--flush;
}

.image-widget__text {
    margin-top: $space-quarter;
}
