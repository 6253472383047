$toggle-width: $space;
$toggle-height: $toggle-width;
$toggle-line-height: 2px;

.nav-toggle {
    display: inline-block;
    vertical-align: middle;
    width: $toggle-width;

    @include respond-min($tablet-break) {
        margin-left: $space-half;
    }
}

.nav-toggle__line {
    backface-visibility: hidden;
    background-color: $color-white;
    border-radius: $border-width-small;
    display: block;
    height: $toggle-line-height;
    margin: $space-quarter 0;
    position: relative;
    transition: $transition-speed-fast all;
    transition-delay: $transition-speed;
}

.nav-toggle__line--1 {
    top: 0;

    .is-active & {
        top: $toggle-height / 3;
        transform: rotate(-45deg);
    }
}

.nav-toggle__line--2 {
    .is-active & {
        opacity: 0;
    }
}

.nav-toggle__line--3 {
    bottom: 0;

    .is-active & {
        bottom: $toggle-height / 3;
        transform: rotate(45deg);
    }
}
