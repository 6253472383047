.nav {
    @extend %cf;

    list-style: none;
    margin: 0;
    padding: 0;
}

.nav__item,
.nav__link {
    display: inline-block;
}

.nav__heading {
    @extend %boxed__heading;

    &:first-child {
        margin-top: 0;
    }
}
