$responsive-table-breakpoint: $x-small-width !default;
$responsive-table-border-style: $border-width-small solid $color-border-mid !default;
$responsive-table-gutter: $space-quarter !default;

table:not(.calendar__table):not(.form__matrix) {
    .js & {
        border: 0;

        @include respond-max($responsive-table-breakpoint) {
            // scss-lint:disable ImportantRule
            max-width: 100% !important;
            width: 100% !important;    // overwrite widths that can be added through editor
            // scss-lint:enable ImportantRule
        }

        caption,
        tbody,
        tr,
        th,
        td,
        tfoot {
            @include respond-max($responsive-table-breakpoint) {
                display: block;
                // scss-lint:disable ImportantRule
                max-width: 100% !important;
                width: 100% !important;    // overwrite widths that can be added through editor
                // scss-lint:enable ImportantRule
            }
        }

        thead {
            @include respond-max($responsive-table-breakpoint) {
                display: none;
            }
        }

        tr {
            @include respond-max($responsive-table-breakpoint) {
                &:not(:last-child) {
                    border-bottom: $responsive-table-border-style;
                    padding-bottom: $space-half;
                }
            }
        }

        tfoot {
            @include respond-max($responsive-table-breakpoint) {
                border-top: $responsive-table-border-style;
                margin-top: $space-half;
            }
        }

        .mobile-th {
            display: inline;
            font-weight: $font-weight-bold;

            @include respond-min($responsive-table-breakpoint) {
                display: none;
            }
        }

        .visually-hidden {
            + .mobile-th__seperator {
                display: none;
            }
        }
    }

    .no-js & {
        border: 0;
        display: block;
        overflow-x: auto;
    }

    .responsive & {
        @extend %cf;

        // scss-lint:disable ImportantRule
        max-width: 100% !important;
        width: 100% !important;    // overwrite widths that can be added through editor
        // scss-lint:enable ImportantRule

        caption,
        tbody,
        tr,
        th,
        td,
        tfoot {
            display: block;
            // scss-lint:disable ImportantRule
            max-width: 100% !important;
            width: 100% !important;    // overwrite widths that can be added through editor
            // scss-lint:enable ImportantRule
        }

        thead {
            display: none;
        }

        tr {
            @extend %cf;

            &:not(:last-child) {
                border-bottom: $responsive-table-border-style;
                padding-bottom: $space-half;
            }

            &:first-child {
                @include respond-min($mid-width) {
                    td:nth-child(2) {
                        margin-top: 0;
                    }

                    th + td:nth-child(2) {
                        margin-top: $space-half;
                    }
                }

                th,
                td {
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        td {
            @include textWordbreak();

            @include respond-min($mid-width) {
                float: left;
                margin-right: $responsive-table-gutter;
                // scss-lint:disable ImportantRule
                width: calc(50% - #{$responsive-table-gutter}) !important;    // overwrite widths that can be added through editor
                // scss-lint:enable ImportantRule

                &:nth-of-type(even) {
                    margin-left: $responsive-table-gutter;
                    margin-right: 0;
                }

                &:nth-of-type(odd) {
                    clear: both;
                }
            }
        }

        tfoot {
            border-top: $responsive-table-border-style;
            margin-top: $responsive-table-gutter * 2;
            padding-top: $space-half;
        }

        .mobile-th {
            display: inline;
        }
    }
}

.table-wrapper {
    .js & {
        max-width: 100%;
        overflow: hidden;
    }
}

// temporary scroller for matrix table
.table-wrapper-matrix {
    margin: 0 0 $space;
    max-width: 100%;
    overflow-x: auto;

    &.has-scroll {
        overflow: hidden;
        position: relative;

        &::after {
            border-radius: 10px 0 0 10px / 50% 0 0 50%;
            box-shadow: -5px 0 10px rgba(0, 0, 0, .25);
            content: '';
            height: 100%;
            left: 100%;
            position: absolute;
            top: 0;
            width: 50px;
        }

        .scroller {
            overflow-x: auto;

            &::-webkit-scrollbar {
                height: $space-half;
            }

            &::-webkit-scrollbar-track {
                background: #f0f0f0;
                box-shadow: inset 0 0 2px rgba(0, 0, 0, .15);
            }

            &::-webkit-scrollbar-thumb {
                background: #ccc;
                border-radius: $space-quarter;
            }
        }
    }

    table {
        .site-content &:not([class]) {
            margin-bottom: 0;
        }
    }
}
